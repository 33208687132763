import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useDesigner } from "@designer-suite";
import { CurvePanel } from "@shared/features/ContextualToolbar";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { getSelectedCurve, updateCurve } from "./curveToolUtilities";
import { curveMessages } from "./CurveToolMessages";
import { useSelection } from "../../designer/useSelection";

interface CurveToolSheetContentProps /* extends Props */ {
    /** Action the back button should take */
    onBackClick: () => void;
}
/**
 * This Sheet is responsible for showing the curve tools in a sheet
 */
export function CurveToolSheetContent({ onBackClick, ...restOfProps }: CurveToolSheetContentProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection("model:change:data");

    if (!designer || !selection || selection.length === 0) {
        return null;
    }
    const selectedCurve = getSelectedCurve(selection);

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(curveMessages.curveToolTitleText.id)} />
            <SheetContent className="sheet-vertical-selection-set">
                <CurvePanel
                    selectedCurve={selectedCurve}
                    onChange={(selectedValue: string) => updateCurve(designer, selection, selectedValue)}
                />
            </SheetContent>
        </>
    );
}
CurveToolSheetContent.displayName = "CurveToolSheetContent";
