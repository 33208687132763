import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "./Button";
import { ArrangeIcon } from "./Icons/ArrangeIcon";

// Used in LayersToolExpandingButton
export const messages = defineMessages({
    buttonTitle: {
        id: "easel.designerSuite.layersButton.title",
        defaultMessage: "Arrange",
        description: {}
    }
});

interface LayersSheetButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    /** For opening up the layers tool panel */
    onClick: () => void;
    isDisabled: boolean;

    /** Whether the button should return the the icon
     * @default false
     */
    showIcon?: boolean;
}

/**
 * This button is responsible for opening the layer tool
 */
export function LayersSheetButton({
    className = "tool-icon",
    onClick,
    showIcon,
    isDisabled,
    ...restOfProps
}: LayersSheetButtonProps) {
    const { t } = useTranslationSSR();

    return (
        <Button onClick={onClick} isDisabled={isDisabled} className={className} {...restOfProps}>
            {showIcon && <ArrangeIcon aria-hidden={true} />}
            <div className="dcl-ctx-button-label">{t(messages.buttonTitle.id)}</div>
        </Button>
    );
}
LayersSheetButton.displayName = "LayersSheetButton";
