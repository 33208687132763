import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useSelection, useDesigner } from "@designer-suite";
import { Button, CurvedTextIcon } from "@shared/features/StudioChrome";
import { curveMessages } from "./CurveToolMessages";
import "./curveToolbarButton.scss";
import { getIsWordArtCompatible } from "./curveToolUtilities";

interface CurveToolButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    showIcon?: boolean;
    /** For opening the curve tool in a sheet */
    onClick: () => void;
}

export function CurveToolButton({ className = "tool-icon", onClick, showIcon, ...restOfProps }: CurveToolButtonProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection();

    return (
        <Button
            onClick={onClick}
            title={t(curveMessages.curveToolTitle.id)}
            isDisabled={!getIsWordArtCompatible(designer, selection)}
            className={className}
            {...restOfProps}
        >
            {showIcon && <CurvedTextIcon />}
            <div className="dcl-ctx-button-label">{t(curveMessages.curveToolText.id)}</div>
        </Button>
    );
}
CurveToolButton.displayName = "CurveToolButton";
