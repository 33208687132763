import React from "react";
import { Button, TableDesignIcon } from "@shared/features/StudioChrome";

import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";

const messages = defineMessages({
    buttonTitle: {
        id: "easel.designerSuite.tableDesignSelectorButton.title",
        defaultMessage: "Table Style",
        description: {}
    }
});

interface TableDesignSelectorButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    /** For opening up the table design selection tool panel */
    onClick: () => void;
    /** Whether the button should return the icon, text, or both  */
    displayType: "iconOnly" | "textOnly" | "both";
}

export function TableDesignSelectorButton(props: TableDesignSelectorButtonProps) {
    const { onClick, className = "tool-icon", displayType = "both", ...rest } = props;
    const { t } = useTranslationSSR();

    return (
        <Button onClick={onClick} title={t(messages.buttonTitle.id)} isDisabled={false} className={className} {...rest}>
            {displayType === "iconOnly" && (
                <div className="dcl-ctx-button-label">
                    <TableDesignIcon aria-hidden={true} />
                </div>
            )}
            {displayType === "both" && <TableDesignIcon />}
            {(displayType === "textOnly" || displayType === "both") && (
                <div className="dcl-ctx-button-label">{t(messages.buttonTitle.id)}</div>
            )}
        </Button>
    );
}
TableDesignSelectorButton.displayName = "TableDesignSelectorButton";
