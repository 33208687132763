import { getTableFontFamily } from "@utilities";
import { ItemReferenceTypes } from "@shared/utils/CimDoc";

function isReferenceItem(selection: ReferenceItem | TextItem): selection is ReferenceItem {
    return (selection as ReferenceItem).type !== undefined;
}

function isTextItem(selection: ReferenceItem | TextItem): selection is TextItem {
    return (selection as TextItem).font !== undefined;
}

type ItemSelectionSet = (ReferenceItem | TextItem)[];

export function getSelectedFont(selection: ItemSelectionSet) {
    const selectedFontFamilies = selection.reduce<string[]>((accumulator, current) => {
        if (isReferenceItem(current)) {
            if (current.type === ItemReferenceTypes.WORD_ART) {
                if (accumulator.includes(current.data.fontFamily)) {
                    return accumulator;
                }
                return [...accumulator, current.data.fontFamily];
            }
            if (current.type === ItemReferenceTypes.TABLE) {
                const fontFamily = getTableFontFamily(current.data);
                if (fontFamily) {
                    if (accumulator.includes(fontFamily)) {
                        return accumulator;
                    }
                    return [...accumulator, fontFamily];
                }
                return accumulator;
            }
            if (current.type === ItemReferenceTypes.TEAMS_NAME) {
                const { fontFamily } = current.data;
                if (accumulator.includes(fontFamily)) {
                    return accumulator;
                }
                return [...accumulator, fontFamily];
            }
        }
        if (isTextItem(current)) {
            if (current.font && accumulator.includes(current.font)) {
                return accumulator;
            }
            return [...accumulator, current.font];
        }
        return accumulator;
    }, []);
    return selectedFontFamilies.length === 1 ? selectedFontFamilies[0] : "";
}
