import React from "react";

import { useSelection, useDesigner, DeleteTool, useActiveCanvas, isLayersToolCompatible } from "@designer-suite";
import { ColorIconType, ColorPickerButton } from "@presentational";
import {
    LayersSheetButton,
    SheetContent,
    SheetFontSelectionContainer,
    SheetFormattingContainer,
    SheetHorizontalDivider,
    SheetHorizontalScroller
} from "@shared/features/StudioChrome";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { getTrackingDataForSelection } from "@utilities";
import { TeamsFontFamilyButton } from "../TeamsFontSelector/TeamsFontFamilyButton";
import { JoystickButton } from "../Joystick";
import { RotateSheetButton } from "../RotateSheetButton/RotateSheetButton";
import TeamsFontSizeButtonContainer from "../../ToolbarComponents/TeamsFontSize/TeamsFontSizeButtonContainer";

interface TeamsContentProps {
    /** Action to open the joystick */
    openJoystick: () => void;
    /** Action to open the arrange tool */
    openArrangeTools: () => void;
    /** Action to open the text color picker */
    openTextColorPicker: () => void;
    /** Action to open the font selector tool */
    openFontSelectorTools: () => void;
    /** Action to open the rotate slider */
    openRotateSlider: () => void;
}

export function TeamsSheetContentButtons({
    openJoystick,
    openArrangeTools,
    openTextColorPicker,
    openFontSelectorTools,
    openRotateSlider
}: TeamsContentProps) {
    const selection = useSelection();
    const designer = useDesigner();
    const canvas = useActiveCanvas();

    if (!designer || selection.length !== 1) {
        return null;
    }

    return (
        <>
            <SheetContent className="teams-sheet-content">
                <SheetFormattingContainer>
                    <ColorPickerButton
                        icon={ColorIconType.TextColorIcon}
                        onClick={openTextColorPicker}
                        isDisabled={false}
                        displayType="iconOnly"
                    />
                </SheetFormattingContainer>
                <SheetHorizontalDivider />
                <SheetFontSelectionContainer>
                    <TeamsFontFamilyButton onClick={openFontSelectorTools} />
                    <TeamsFontSizeButtonContainer expandable={false} className="sheet-font-size-selector" />
                </SheetFontSelectionContainer>
                <SheetHorizontalDivider />
                <SheetHorizontalScroller reducedHeight>
                    <DeleteTool showIcon />
                    <RotateSheetButton onClick={openRotateSlider} />
                    <LayersSheetButton
                        showIcon
                        onClick={() => {
                            designer &&
                                designer.eventBus.trigger(
                                    STUDIO_TRACKING_EVENTS.CLICK_ARRANGE,
                                    getTrackingDataForSelection(selection)
                                );
                            openArrangeTools();
                        }}
                        isDisabled={!isLayersToolCompatible(designer, selection, canvas)}
                    />
                    <JoystickButton showIcon onClick={openJoystick} />
                </SheetHorizontalScroller>
            </SheetContent>
        </>
    );
}

TeamsSheetContentButtons.displayName = "TeamsSheetContentButtons";
