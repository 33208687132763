import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { updateSelectedItems, getTableStyleId, updateTableStyleId } from "@utilities";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { TableDesignSelector } from "@shared/features/Tables";
import { useSelection } from "@designer-suite";
import { useDesigner } from "../../designer/DesignerProvider";
import { useTableSelectionFilter } from "../../hooks/useTableSelectionFilter";

import "./tableDesignSelector.scss";

const messages = defineMessages({
    tableDesignSelTitleText: {
        id: "easel.ui.TableDesignSelectorSheetContent.tableDesignSelTitleText",
        defaultMessage: "Table styles",
        description: {
            note: "Display at the header of the table design selector sheet for mobile"
        }
    }
});

interface TableDesignSelectorSheetContentProps {
    /** Action the back button should take */
    onBackClick: () => void;
}

const listenEvents = "model:change:data";

/**
 * This Sheet is responsible for displaying the table design selections in a sheet
 */
export function TableDesignSelectorSheetContent({ onBackClick }: TableDesignSelectorSheetContentProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const { tableItems, otherItems } = useTableSelectionFilter(useSelection(listenEvents));

    if (!designer || tableItems.length !== 1 || otherItems.length) {
        return null;
    }

    const styleId = getTableStyleId(tableItems[0].data);

    function onChange(styleId: number) {
        updateSelectedItems(designer, tableItems, table => {
            updateTableStyleId(table, styleId);
        });
    }

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(messages.tableDesignSelTitleText.id)} />
            <SheetContent className="table-design-selector-sheet-content">
                <TableDesignSelector onChange={onChange} selectedId={styleId ?? 0} />
            </SheetContent>
        </>
    );
}
TableDesignSelectorSheetContent.displayName = "TableDesignSelectorSheetContent";
