import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import classNames from "classnames";
import { MoveArrowIcon } from "@shared/features/StudioChrome";
import { JoystickPanelButton } from "./JoystickPanelButton";
import * as styles from "./JoystickPanelContent.module.scss";

const messages = defineMessages({
    upButton: {
        id: "easel.ui.joystick.upbutton.title",
        defaultMessage: "Up",
        description: {
            note: "Mouse over text for the Up arrow button"
        }
    },
    downButton: {
        id: "easel.ui.joystick.downbutton.title",
        defaultMessage: "Down",
        description: {
            note: "Mouse over text for the Down arrow button"
        }
    },
    rightButton: {
        id: "easel.ui.joystick.rightbutton.title",
        defaultMessage: "Right",
        description: {
            note: "Mouse over text for the Right arrow button"
        }
    },
    leftButton: {
        id: "easel.ui.joystick.leftbutton.title",
        defaultMessage: "Left",
        description: {
            note: "Mouse over text for the Left arrow button"
        }
    }
});

export enum Direction {
    Up = "Up",
    Down = "Down",
    Left = "Left",
    Right = "Right"
}

export interface JoystickProps {
    /** For overriding styles */
    className?: string;
    startMove: (direction: Direction) => void;
    onMoveEnd: () => void;
    showJoystick?: boolean;
}

/**
 * This panel of buttons is responsible for moving the selected item
 * Intended for Mobile only
 */
export function JoystickPanelContent({ className, startMove, onMoveEnd, showJoystick }: JoystickProps) {
    const { t } = useTranslationSSR();

    return (
        <>
            {showJoystick && (
                <div className={classNames(styles.joystickTool, className)}>
                    <div data-dcl-prevent-canvas-items-deselection className={styles.joystickContainer}>
                        <JoystickPanelButton
                            className={styles.joystickPanelButtonLeft}
                            title={t(messages.leftButton.id)}
                            onStart={() => startMove(Direction.Left)}
                            onEnd={() => onMoveEnd()}
                        >
                            <MoveArrowIcon className={styles.joystickArrowLeft} />
                        </JoystickPanelButton>
                        <JoystickPanelButton
                            className={styles.joystickPanelButtonUp}
                            title={t(messages.upButton.id)}
                            onStart={() => startMove(Direction.Up)}
                            onEnd={() => onMoveEnd()}
                        >
                            <MoveArrowIcon className={styles.joystickArrowUp} />
                        </JoystickPanelButton>
                        <JoystickPanelButton
                            className={styles.joystickPanelButtonDown}
                            title={t(messages.downButton.id)}
                            onStart={() => startMove(Direction.Down)}
                            onEnd={() => onMoveEnd()}
                        >
                            <MoveArrowIcon className={styles.joystickArrowDown} />
                        </JoystickPanelButton>
                        <JoystickPanelButton
                            className={styles.joystickPanelButtonRight}
                            title={t(messages.rightButton.id)}
                            onStart={() => startMove(Direction.Right)}
                            onEnd={() => onMoveEnd()}
                        >
                            <MoveArrowIcon />
                        </JoystickPanelButton>
                    </div>
                </div>
            )}
        </>
    );
}
JoystickPanelContent.displayName = "JoystickPanelContent";
