import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { textFilter } from "@utilities";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelection } from "../../designer/useSelection";
import { TextListOptionGroupStudio5 } from "../../ToolbarComponents/TextLists/TextListOptionGroupStudio5";

const messages = defineMessages({
    title: {
        id: "easel.ui.ListToolsSheetContent.title",
        defaultMessage: "List",
        description: {
            note: "Display at the header of the listTool sheet for mobile"
        }
    }
});

interface Props {
    /** Action the back button should take */
    onBackClick: () => void;
}
/**
 * This Sheet is responsible for showing listtool tool in a sheet
 */
export function TextListSheetContent({ onBackClick }: Props) {
    const designer = useDesigner();
    const { t } = useTranslationSSR();
    const selection = useSelection("model:change:content item:focus");
    const { matchingItems, otherItems } = textFilter(designer, selection);

    if (!designer || matchingItems.length === 0 || otherItems.length > 0) {
        return null;
    }

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(messages.title.id)} />
            <SheetContent className="sheet-vertical-selection-set">
                <TextListOptionGroupStudio5 />
            </SheetContent>
        </>
    );
}
TextListSheetContent.displayName = "TextListSheetContent";
