import React from "react";

export function MoveArrowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path d="M2 8L14 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <path
                d="M10 12L14 8C14 8 11.5238 5.52381 10 4"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
MoveArrowIcon.displayName = "MoveArrowIcon";
