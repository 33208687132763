import React from "react";
import { Sheet } from "@shared/features/StudioChrome";
import { useSelection, useDesigner } from "@designer-suite";
import { isWordArt, isTable, isTeamsPlaceholder, isImageUnreplacedPlaceholder } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useActiveDialog } from "@shared/features/ActiveDialog";
import { SubselectionType, useTableSubselection } from "@shared/features/Tables";
import { ImageSheetContentContainer } from "./Images/ImageSheetContentContainer";
import { TextSheetContentContainer } from "./Text/TextSheetContentContainer";
import { ShapeSheetContentContainer } from "./Shapes/ShapeSheetContentContainer";
import { WordArtSheetContentContainer } from "./WordArt/WordArtSheetContentContainer";
import { ImagePlaceholderSheetContentContainer } from "./ImagePlaceholders/ImagePlaceholderSheetContentContainer";
import { ItemReferenceSheetContentContainer } from "./ItemReference/ItemReferenceSheetContentContainer";
import { TablesSheetContentContainer } from "./Tables/TablesSheetContentContainer";
import { TeamsSheetContentContainer } from "./Teams/TeamsSheetContentContainer";
import { TableColumnContentButtons } from "./TableColumn/TableColumnContentButtons";
import { TableRowContentButtons } from "./TableRow/TableRowContentButtons";
import { isQRCode } from "../QRCodes/QRCodeUtils";
import { QRCodesSheetContentContainer } from "./QRCodes/QRCodesSheetContentContainer";

// TODO, as we create new tool sheets not divs, they can use the toggleSheetFunction for use on their close button
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getToolSheetContent = (item: CanvasItem, tableSubselectionType: SubselectionType) => {
    if (isWordArt(item)) {
        return { component: WordArtSheetContentContainer, removeRoundedBorder: false };
    }

    if (isQRCode(item)) {
        return { component: QRCodesSheetContentContainer, removeRoundedBorder: true };
    }

    // if a teams placeholder is selected, then show the Teams Sheet
    if (isTeamsPlaceholder(item)) {
        return { component: TeamsSheetContentContainer, removeRoundedBorder: false };
    }

    // Right now if this is a table, check to see if just the table is selected. If it is, then show the Table Sheet
    // Otherwise, fall through to below.  TODO: Check for the different types in the future
    if (isTable(item)) {
        switch (tableSubselectionType) {
            case SubselectionType.None:
                return { component: TablesSheetContentContainer, removeRoundedBorder: false };
            case SubselectionType.Column:
                return { component: TableColumnContentButtons, removeRoundedBorder: false };
            case SubselectionType.Row:
                return { component: TableRowContentButtons, removeRoundedBorder: false };
            case SubselectionType.Cell:
                return { component: null, removeRoundedBorder: true }; // TBD - should open text editor
            default:
                break;
        }
    }

    const { itemType } = item;
    const sheetContent = {
        [ItemTypes.IMAGE]: isImageUnreplacedPlaceholder(item)
            ? { component: ImagePlaceholderSheetContentContainer, removeRoundedBorder: true }
            : { component: ImageSheetContentContainer, removeRoundedBorder: true },
        [ItemTypes.SHAPE]: { component: ShapeSheetContentContainer, removeRoundedBorder: true },
        [ItemTypes.TEXT]: { component: TextSheetContentContainer, removeRoundedBorder: false },
        [ItemTypes.ITEM_REFERENCE]: { component: ItemReferenceSheetContentContainer, removeRoundedBorder: true }
    };
    return sheetContent[itemType];
};

export function ContextualToolSheet() {
    const selection = useSelection("model:change:placeholderReplaced");
    const designer = useDesigner();
    const { setAddButtonOffset } = useActiveDialog();
    const {
        selection: { type: tableSubselectionType }
    } = useTableSubselection();

    const reportSizeChanged = (rect: DOMRect) => {
        if (rect) {
            setAddButtonOffset(rect.height + 16);
        }
    };

    if (!designer || !selection || selection.length !== 1) {
        return null;
    }

    const { component: Component, removeRoundedBorder } = getToolSheetContent(selection[0], tableSubselectionType);

    return (
        <Sheet
            reportSizeChange={reportSizeChanged}
            removeRoundedBorder={removeRoundedBorder}
            data-testid="contextual-text-sheet"
        >
            {Component && <Component />}
        </Sheet>
    );
}

ContextualToolSheet.displayName = "ContextualToolSheet";
