import React, { useCallback, useEffect } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { LayersToolSheetContent } from "../LayersTool/LayersToolSheetContent";
import { JoystickSheetContent } from "../Joystick";
import { ShapeSheetContentButtons } from "./ShapeSheetContentButtons";
import { ColorPickerSheetContent, ColorType } from "../ColorPicker/ColorPickerSheetContent";
import { StrokeWidthSheetContent } from "../StrokeWidth/StrokeWidthSheetContent";
import { EditShapeSheetContent } from "./EditShapeSheetContent";
import { RotateButtonSheetContent } from "../RotateSheetButton/RotateSheetButtonContent";

const messages = defineMessages({
    fillColorTitle: {
        id: "easel.ui.sheets.shapeSheetContentContainer.fillColorTitle",
        defaultMessage: "Shape fill color"
    },
    strokeColorTitle: {
        id: "easel.ui.sheets.shapeSheetContentContainer.strokeColorTitle",
        defaultMessage: "Shape outline color"
    }
});

export function ShapeSheetContentContainer() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { t } = useTranslationSSR();

    const openShapeTools = useCallback(() => setCurrentActiveDialog(DialogType.ShapeTools), [setCurrentActiveDialog]);
    const openJoystick = useCallback(() => setCurrentActiveDialog(DialogType.JoystickTools), [setCurrentActiveDialog]);
    const openArrangeTools = useCallback(
        () => setCurrentActiveDialog(DialogType.ArrangeTools),
        [setCurrentActiveDialog]
    );
    const openFillColorPicker = useCallback(
        () => setCurrentActiveDialog(DialogType.FillColorPicker),
        [setCurrentActiveDialog]
    );
    const openStrokeColorPicker = useCallback(
        () => setCurrentActiveDialog(DialogType.StrokeColorPicker),
        [setCurrentActiveDialog]
    );
    const openStrokeWidthSelector = useCallback(
        () => setCurrentActiveDialog(DialogType.StrokeWidthSelector),
        [setCurrentActiveDialog]
    );
    const openEditShape = useCallback(() => setCurrentActiveDialog(DialogType.EditShapes), [setCurrentActiveDialog]);
    const openRotateSlider = useCallback(
        () => setCurrentActiveDialog(DialogType.RotateTools),
        [setCurrentActiveDialog]
    );

    useEffect(() => {
        setCurrentActiveDialog(DialogType.ShapeTools);
    }, [setCurrentActiveDialog]);

    return (
        <React.Fragment>
            {currentActiveDialog === DialogType.JoystickTools && <JoystickSheetContent onBackClick={openShapeTools} />}
            {currentActiveDialog === DialogType.FillColorPicker && (
                <ColorPickerSheetContent
                    colorType={ColorType.FillColor}
                    onBackClick={openShapeTools}
                    title={t(messages.fillColorTitle.id)}
                />
            )}
            {currentActiveDialog === DialogType.StrokeColorPicker && (
                <ColorPickerSheetContent
                    colorType={ColorType.StrokeColor}
                    onBackClick={openShapeTools}
                    title={t(messages.strokeColorTitle.id)}
                />
            )}
            {currentActiveDialog === DialogType.StrokeWidthSelector && (
                <StrokeWidthSheetContent onBackClick={openShapeTools} />
            )}
            {currentActiveDialog === DialogType.ArrangeTools && <LayersToolSheetContent onBackClick={openShapeTools} />}
            {currentActiveDialog === DialogType.EditShapes && <EditShapeSheetContent onBackClick={openShapeTools} />}
            {currentActiveDialog === DialogType.RotateTools && (
                <RotateButtonSheetContent onBackClick={openShapeTools} />
            )}
            {currentActiveDialog === DialogType.ShapeTools && (
                <ShapeSheetContentButtons
                    openJoystick={openJoystick}
                    openArrangeTools={openArrangeTools}
                    openFillColorPicker={openFillColorPicker}
                    openStrokeColorPicker={openStrokeColorPicker}
                    openStrokeWidthSelector={openStrokeWidthSelector}
                    openEditShape={openEditShape}
                    openRotateSlider={openRotateSlider}
                />
            )}
        </React.Fragment>
    );
}

ShapeSheetContentContainer.displayName = "ShapeSheetContentContainer";
