import React from "react";
import {
    useSelection,
    useDesigner,
    DeleteTool,
    DuplicateTool,
    TableFontSizeSelector,
    useActiveCanvas,
    isLayersToolCompatible
} from "@designer-suite";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ColorIconType, ColorPickerButton, TableDesignSelectorButton } from "@presentational";
import {
    LayersSheetButton,
    SheetContent,
    SheetFontSelectionContainer,
    SheetFormattingContainer,
    SheetHorizontalDivider,
    SheetHorizontalScroller,
    SheetTextStylesContainer
} from "@shared/features/StudioChrome";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { getTrackingDataForSelection } from "@utilities";
import { JoystickButton } from "../Joystick";
import { TableBoldButton } from "../../ToolbarComponents/Buttons/TableBoldButton";
import { TableItalicButton } from "../../ToolbarComponents/Buttons/TableItalicButton";
import { EditTableTool } from "../../ToolbarComponents/Buttons/EditTableTool";
import { TableFontFamilyButton } from "../TableFontSelector/TableFontFamilyButton";
import { AlignmentToolButton } from "../../ToolbarComponents/Alignment/AlignmentToolButton";
import { RotateSheetButton } from "../RotateSheetButton/RotateSheetButton";

export const messages = defineMessages({
    tableColor: {
        id: "easel.components.tables.tableColor",
        defaultMessage: "Background Color",
        description: {
            note: "Table color button title"
        }
    }
});

interface TablesContentProps {
    /** Action to open the joystick */
    openJoystick: () => void;
    /** Action to open the arrange tool */
    openArrangeTools: () => void;
    /** Action to open the text color picker */
    openTextColorPicker: () => void;
    /** Action to open the background color picker */
    openBackgroundColorPicker: () => void;
    /** Action to open the font selector tool */
    openFontSelectorTools: () => void;
    /** Action to open the table design selector tool */
    openDesignSelectorTools: () => void;
    /** Action to open the alignment tool */
    openAlignmentTool: () => void;
    /** Action to open the rotate slider */
    openRotateSlider: () => void;
}

// TBD: Needs update when table background color icon is available
export function TablesSheetContentButtons({
    openJoystick,
    openArrangeTools,
    openTextColorPicker,
    openBackgroundColorPicker,
    openFontSelectorTools,
    openDesignSelectorTools,
    openAlignmentTool,
    openRotateSlider
}: TablesContentProps) {
    const selection = useSelection();
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const { t } = useTranslationSSR();

    if (!designer || selection.length !== 1) {
        return null;
    }

    return (
        <>
            <SheetContent className="tables-sheet-content">
                <SheetFormattingContainer>
                    <ColorPickerButton
                        icon={ColorIconType.TextColorIcon}
                        onClick={openTextColorPicker}
                        isDisabled={false}
                        displayType="iconOnly"
                    />
                    <SheetTextStylesContainer>
                        <TableBoldButton />
                        <TableItalicButton />
                    </SheetTextStylesContainer>
                    <ColorPickerButton
                        icon={ColorIconType.TableColorIcon}
                        onClick={openBackgroundColorPicker}
                        isDisabled={false}
                        displayType="iconOnly"
                        title={t(messages.tableColor.id)}
                    />
                </SheetFormattingContainer>
                <SheetHorizontalDivider />
                <SheetFontSelectionContainer>
                    <TableFontFamilyButton onClick={openFontSelectorTools} />
                    <TableFontSizeSelector expandable={false} />
                </SheetFontSelectionContainer>
                <SheetHorizontalDivider />
                <SheetHorizontalScroller reducedHeight>
                    <DeleteTool showIcon />
                    <EditTableTool showIcon />
                    <RotateSheetButton onClick={openRotateSlider} />
                    <AlignmentToolButton showIcon onClick={openAlignmentTool} />
                    <TableDesignSelectorButton displayType="both" onClick={openDesignSelectorTools} />
                    <LayersSheetButton
                        showIcon
                        onClick={() => {
                            designer &&
                                designer.eventBus.trigger(
                                    STUDIO_TRACKING_EVENTS.CLICK_ARRANGE,
                                    getTrackingDataForSelection(selection)
                                );
                            openArrangeTools();
                        }}
                        isDisabled={!isLayersToolCompatible(designer, selection, canvas)}
                    />
                    <DuplicateTool showIcon />
                    <JoystickButton showIcon onClick={openJoystick} />
                </SheetHorizontalScroller>
            </SheetContent>
        </>
    );
}

TablesSheetContentButtons.displayName = "TablesSheetContentButtons";
