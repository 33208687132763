import React from "react";
import { Button, PremiumFinishIcon } from "@shared/features/StudioChrome";

import classNames from "classnames";
import { useSelection } from "@designer-suite";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useHasFinish } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppSelector } from "@shared/redux";
import { useTrackEvents } from "@shared/features/Tracking";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import "./premiumFinishSheetButton.scss";

const messages = defineMessages({
    premiumFinishButtonTitle: {
        id: "easel.ui.premiumFinish.title",
        defaultMessage: "Finishes",
        description: {
            note: "Premium finish sheet open on click"
        }
    }
});

interface PremiumFinishSheetButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    /** Whether the button should return the the icon
     * @default false
     */
    showIcon?: boolean;
}

/**
 * This button is responsible for opening the list tools
 */
export function PremiumFinishSheetButton({
    className = "tool-icon",
    showIcon,
    ...restOfProps
}: PremiumFinishSheetButtonProps) {
    const { t } = useTranslationSSR();
    const selection = useSelection(
        "model:change:overprints model:change:premiumFinish model:change:selected model:change:data"
    );
    const hasFinish = useHasFinish(selection);
    const isFullBleed = useAppSelector(state => state.isFullBleed);

    const { trackEvent } = useTrackEvents();
    const { setCurrentActiveDialog } = useActiveDialog();

    if (isFullBleed) {
        return null;
    }

    return (
        <Button
            onClick={() => {
                trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_PREMIUM_FINISH });
                setCurrentActiveDialog(DialogType.PremiumFinishes);
            }}
            className={className}
            {...restOfProps}
        >
            {showIcon && <PremiumFinishIcon className={classNames({ "has-finish": hasFinish })} />}
            <div className="dcl-ctx-button-label">{t(messages.premiumFinishButtonTitle.id)}</div>
        </Button>
    );
}
PremiumFinishSheetButton.displayName = "PremiumFinishSheetButton";
