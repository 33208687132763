import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Button, ExpandingButtonDownArrow } from "@shared/features/StudioChrome";

import { useSelection } from "../../designer/useSelection";

const messages = defineMessages({
    strokeWidthToolTitle: {
        id: "easel.ui.StrokeWidthButton.Title",
        defaultMessage: "Stroke Width",
        description: {
            note: "Opens tool to select a different stroke width"
        }
    }
});

interface Props {
    className?: string;
    /** Click handler for when button is clicked */
    onClick: () => void;

    showDownArrow?: boolean;
}

/**
 * This button is responsible for opening a stroke width sheet and displaying current width
 */
export function StrokeWidthButton({ className = "tool-icon", onClick, showDownArrow = false }: Props) {
    const { t } = useTranslationSSR();
    const selection = useSelection("model:change:strokeWidth");

    const selectedStrokeWidths = selection.map((item: ShapeItem) => item.strokeWidth);
    const differentStrokeWidthsPresent =
        selectedStrokeWidths.reduce((set, strokeWidth) => set.add(strokeWidth), new Set<number>()).size !== 1;
    const selectedLabel = differentStrokeWidthsPresent
        ? `${Math.trunc(Math.min(...selectedStrokeWidths))}pt+`
        : `${Math.trunc(selectedStrokeWidths[0])}pt`;

    return (
        <Button title={t(messages.strokeWidthToolTitle.id)} className={className} onClick={onClick}>
            <div className="dcl-ctx-button-value">{selectedLabel}</div>
            {showDownArrow && (
                <ExpandingButtonDownArrow aria-hidden={true} className="expandingButton-arrowIconStyle" />
            )}
        </Button>
    );
}
StrokeWidthButton.displayName = "StrokeWidthButton";
