import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useRecentFontsContext, useCreateRenderFontResultForStudio5, useEnableFontBrowser } from "@presentational";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { getTrackingDataForSelection } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { FontSelectorPanel } from "@shared/features/ContextualToolbar";
import { useFontSelectorFontListStudio5 } from "@designer-suite";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelectedFonts } from "./useSelectedFonts";
import { useOnFontSelect, onSelectWordArt } from "../../dropdowns/onFontSelect";

const messages = defineMessages({
    fontSelectorTitleText: {
        id: "easel.ui.fontSelectorSheetContent.fontSelectorTitleText",
        defaultMessage: "Choose Font",
        description: {
            note: "Display at the header of the font selector sheet for mobile"
        }
    }
});

interface FontSelectorSheetContentProps {
    /** Action the back button should take */
    onBackClick: () => void;
}

/**
 * This Sheet is responsible for a font selector in a sheet
 */
export function FontSelectorSheetContent({ onBackClick }: FontSelectorSheetContentProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const { wordArtItems, otherItems, selectedFontFamily, fonts } = useSelectedFonts();
    const { lastSelectedFont, updateRecentFonts, recentFonts, updateLastSelectedFont } = useRecentFontsContext();
    const onSelectText = useOnFontSelect();
    const enableFontBrowser = useEnableFontBrowser();
    const combinedFontList = useFontSelectorFontListStudio5({
        fontList: fonts,
        selectedFontFamily,
        useRecentFonts: useRecentFontsContext
    });

    const renderFontResult = useCreateRenderFontResultForStudio5(combinedFontList);

    function onSelectFont(fontFamily: string, inRecentFonts: boolean) {
        designer &&
            designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT_CHOICE, {
                ...getTrackingDataForSelection([...wordArtItems, ...otherItems]),
                fontFamily,
                inRecentFonts
            });
        onSelectText(otherItems, fontFamily);
        onSelectWordArt(designer, wordArtItems, fontFamily);
    }

    return (
        <>
            <StandardSheetHeader
                onBackClick={() => {
                    onBackClick();
                    updateRecentFonts(lastSelectedFont);
                }}
                sheetTitle={t(messages.fontSelectorTitleText.id)}
            />
            <SheetContent className="font-selector-sheet-content">
                <FontSelectorPanel
                    onOpenFontBrowser={() => {
                        designer && designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT_BROWSER);
                    }}
                    enableFontBrowser={enableFontBrowser}
                    selectedFontFamily={selectedFontFamily}
                    allFonts={combinedFontList.map(font => font.family)}
                    recentFonts={recentFonts}
                    onSelectFont={onSelectFont}
                    updateLastSelectedFont={updateLastSelectedFont}
                    renderFontResult={renderFontResult}
                    className="sheet-font-selector"
                />
            </SheetContent>
        </>
    );
}
FontSelectorSheetContent.displayName = "FontSelectorSheetContent";
