import React from "react";
import { SheetContent, SheetHorizontalScroller } from "@shared/features/StudioChrome";
import { RemoveTableRowTool } from "../../ToolbarComponents/Buttons/RemoveTableRowTool";
import { AddTableRowTool } from "../../ToolbarComponents/Buttons/AddTableRowTool";

// Not using a container, as currently there are no sub sheets

export function TableRowContentButtons() {
    return (
        <SheetContent>
            <SheetHorizontalScroller>
                <AddTableRowTool direction="above" />
                <AddTableRowTool direction="below" />
                <RemoveTableRowTool />
            </SheetHorizontalScroller>
        </SheetContent>
    );
}

TableRowContentButtons.displayName = "TableRowContentButtons";
