/* eslint-disable max-len */
import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}

export function JoystickIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M6 12L8 14C8 14 9.2381 12.7619 10 12"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 10L14 8C14 8 12.7619 6.7619 12 6"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 4L8 2C8 2 6.7619 3.23809 6 4"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 6L2 8C2 8 3.23809 9.2381 4 10"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M8 2V5" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M8 11L8 14" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M5 8H2" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M14 8L11 8" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}
JoystickIcon.displayName = "JoystickIcon";
