import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useRecentColorsContext } from "@presentational";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { TeamsFontColorPickerPanel } from "../../../../studioFive/components/Panels/Redesign/TeamsFontColorPickerPanel";
import { SingleColorImageColorPickerPanel } from "../../../../studioFive/components/Panels/Redesign/SingleColorImageColorPickerPanel";
import { StrokeColorPickerPanel } from "../../../../studioFive/components/Panels/Redesign/StrokeColorPickerPanel";
import { ImageRecolorizationTool } from "../../ToolbarComponents/Buttons/ImageRecolorizationTool";
import { WordArtAndTextColorPickerPanel } from "../../../../studioFive/components/Panels/Redesign/WordArtAndTextColorPickerPanel";
import { FillColorPickerPanel } from "../../../../studioFive/components/Panels/Redesign/FillColorPickerPanel";
import { TableBackgroundColorPickerPanel } from "../../../../studioFive/components/Panels/Redesign/TableBackgroundColorPickerPanel";
import { TableFontColorPickerPanel } from "../../../../studioFive/components/Panels/Redesign/TableFontColorPickerPanel";
import * as styles from "./ColorPickerSheetContent.module.scss";

const messages = defineMessages({
    title: {
        id: "easel.ui.sheets.colorPickerSheet.title",
        defaultMessage: "Color"
    },
    customColorTitle: {
        id: "easel.ui.sheets.colorPickerSheet.customColorTitle",
        defaultMessage: "Custom Color"
    },
    backButtonAccessibilityLabel: {
        id: "easel.ui.sheets.colorPickerSheet.backButtonAccessibilityLabel",
        defaultMessage: "Back",
        description: {
            note: "text for accessibility for the back button"
        }
    }
});

export enum ColorType {
    FillColor = "FillColor",
    StrokeColor = "StrokeColor",
    WordArtAndTextColor = "WordArtAndTextColor",
    TableFontColor = "TableFontColor",
    TableBackgroundColor = "TableBackgroundColor",
    ImageColor = "ImageColor",
    SingleColorImageColor = "SingleColorImageColor",
    TeamsFontColor = "TeamsFontColor"
}

interface ColorPickerSheetContentProps {
    /** Action the back button should take */
    onBackClick: () => void;
    /** Type of Color Picker to show */
    colorType: ColorType;
    /** The sheet title */
    title?: string;
}

/**
 * This Sheet is responsible for showing color picker tool in a sheet
 */
export function ColorPickerSheetContent({ onBackClick, colorType, title }: ColorPickerSheetContentProps) {
    const { t } = useTranslationSSR();
    const { updateRecentColors } = useRecentColorsContext();

    const handleBackClick = () => {
        onBackClick();
        updateRecentColors();
    };

    if (colorType === ColorType.ImageColor) {
        return (
            <>
                <StandardSheetHeader onBackClick={handleBackClick} sheetTitle={title || t(messages.title.id)} />
                <SheetContent className="image-recolorization-sheet-content">
                    <ImageRecolorizationTool displayType="both" contentOnly />
                </SheetContent>
            </>
        );
    }

    return (
        <>
            <StandardSheetHeader onBackClick={handleBackClick} sheetTitle={title || t(messages.title.id)} />
            <SheetContent className={styles.colorPickerSheetContent}>
                {colorType === ColorType.FillColor && <FillColorPickerPanel contentOnly allowCustom={true} />}
                {colorType === ColorType.StrokeColor && <StrokeColorPickerPanel contentOnly allowCustom={true} />}
                {colorType === ColorType.WordArtAndTextColor && <WordArtAndTextColorPickerPanel contentOnly />}
                {colorType === ColorType.SingleColorImageColor && <SingleColorImageColorPickerPanel contentOnly />}
                {colorType === ColorType.TableFontColor && <TableFontColorPickerPanel contentOnly allowCustom={true} />}
                {colorType === ColorType.TableBackgroundColor && (
                    <TableBackgroundColorPickerPanel contentOnly allowCustom={true} />
                )}
                {colorType === ColorType.TeamsFontColor && <TeamsFontColorPickerPanel contentOnly allowCustom={true} />}
            </SheetContent>
        </>
    );
}
ColorPickerSheetContent.displayName = "ColorPickerSheetContent";
