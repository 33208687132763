import React from "react";
import { Typography } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { useFinishType } from "@utilities";
import { FinishMessages } from "@shared/features/PremiumFinish";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelection } from "../../designer/useSelection";
import { PremiumFinishToolbarContent } from "../../../../studioFive/components/Redesign/PremiumFinish/PremiumFinishToolbarContent";

const messages = defineMessages({
    title: {
        id: "easel.ui.PremiumFinishSheetContent.title",
        defaultMessage: "Finishes",
        description: {
            note: "Display at the header of the premium finish sheet for mobile"
        }
    },
    desc: {
        id: "easel.ui.PremiumFinishSheetContent.desc",
        defaultMessage:
            // eslint-disable-next-line max-len
            "Add a [[finishType]] to the selected element.",
        description: {
            note:
                // eslint-disable-next-line max-len
                "Premium finish text"
        }
    }
});

interface Props {
    /** Action the back button should take */
    onBackClick: () => void;
}
/**
 * This Sheet is responsible for showing listtool tool in a sheet
 */
export function PremiumFinishSheetContent({ onBackClick }: Props) {
    const designer = useDesigner();
    const { t } = useTranslationSSR();
    const selection = useSelection(
        "model:change:overprints model:change:premiumFinish change:finishes model:change:selected model:change:data"
    );
    const finishType = useFinishType();

    if (!designer || !selection.length || !finishType) {
        return null;
    }

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(messages.title.id)} />
            <SheetContent className="sheet-vertical-selection-set">
                <Typography textColor="dark-grey" fontSize="-1" pl={1} pb={2}>
                    {t(messages.desc.id, { finishType: t(FinishMessages[finishType].id) })}
                </Typography>
                <PremiumFinishToolbarContent items={selection} />
            </SheetContent>
        </>
    );
}
PremiumFinishSheetContent.displayName = "PremiumFinishSheetContent";
