import React, { useCallback, useEffect } from "react";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { LayersToolSheetContent } from "../LayersTool/LayersToolSheetContent";
import { JoystickSheetContent } from "../Joystick";
import { ItemReferenceSheetContentButtons } from "./ItemReferenceSheetContentButtons";
import { RotateButtonSheetContent } from "../RotateSheetButton/RotateSheetButtonContent";

export function ItemReferenceSheetContentContainer() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();

    const openItemReferenceTools = useCallback(
        () => setCurrentActiveDialog(DialogType.ItemReferenceTools),
        [setCurrentActiveDialog]
    );
    const openJoystick = useCallback(() => setCurrentActiveDialog(DialogType.JoystickTools), [setCurrentActiveDialog]);
    const openArrangeTools = useCallback(
        () => setCurrentActiveDialog(DialogType.ArrangeTools),
        [setCurrentActiveDialog]
    );
    const openRotateSlider = useCallback(
        () => setCurrentActiveDialog(DialogType.ArrangeTools),
        [setCurrentActiveDialog]
    );
    useEffect(() => {
        setCurrentActiveDialog(DialogType.ItemReferenceTools);
    }, [setCurrentActiveDialog]);

    return (
        <React.Fragment>
            {currentActiveDialog === DialogType.JoystickTools && (
                <JoystickSheetContent onBackClick={openItemReferenceTools} />
            )}
            {currentActiveDialog === DialogType.ArrangeTools && (
                <LayersToolSheetContent onBackClick={openItemReferenceTools} />
            )}
            {currentActiveDialog === DialogType.RotateTools && (
                <RotateButtonSheetContent onBackClick={openItemReferenceTools} />
            )}
            {currentActiveDialog === DialogType.ItemReferenceTools && (
                <ItemReferenceSheetContentButtons
                    openJoystick={openJoystick}
                    openArrangeTools={openArrangeTools}
                    openRotateSlider={openRotateSlider}
                />
            )}
        </React.Fragment>
    );
}

ItemReferenceSheetContentContainer.displayName = "ItemReferenceSheetContentContainer";
