import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { EditIcon, Button } from "@shared/features/StudioChrome";

const messages = defineMessages({
    buttonTitle: { id: "easel.designerSuite.editTableButton.title", defaultMessage: "Edit Table", description: {} },
    buttonLabel: { id: "easel.designerSuite.editTableButton.label", defaultMessage: "Edit Table", description: {} }
});

interface EditTableButtonProps {
    showIcon?: boolean;
    className?: string;
    isDisabled?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function EditTableButton({
    showIcon,
    className = "tool-icon",
    isDisabled = false,
    ...restOfProps
}: EditTableButtonProps) {
    const { t } = useTranslationSSR();

    return (
        <Button title={t(messages.buttonTitle.id)} className={className} {...restOfProps}>
            {showIcon && <EditIcon aria-hidden={true} />}
            <div className="dcl-ctx-button-label">{t(messages.buttonLabel.id)}</div>
        </Button>
    );
}

EditTableButton.displayName = "EditTableButton";
