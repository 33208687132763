import React from "react";
import classNames from "classnames";
import { Button } from "@vp/swan";
import * as styles from "./JoystickPanelButton.module.scss";

interface JoystickPanelButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    /** For initiating the start of the move */
    onStart: () => void;
    /** For ending the move */
    onEnd: () => void;
}

export function JoystickPanelButton({
    title,
    children,
    onStart,
    onEnd,
    className,
    ...restOfProps
}: JoystickPanelButtonProps) {
    return (
        <Button
            buttonShape="round"
            title={title}
            aria-label={title}
            className={classNames(styles.joystickPanelButton, className)}
            onPointerDown={onStart}
            onPointerUp={onEnd}
            onPointerLeave={onEnd}
            /* onContextMenu here prevents a bug w/ the context menu showing up after holding the button down */
            onContextMenu={e => e.preventDefault()}
            onDoubleClick={e => e.preventDefault()}
            {...restOfProps}
        >
            {children}
        </Button>
    );
}
JoystickPanelButton.displayName = "JoystickPanelButton";
