import React from "react";
import {
    DuplicateTool,
    useSelection,
    useDesigner,
    DeleteTool,
    useActiveCanvas,
    isLayersToolCompatible
} from "@designer-suite";
import { SheetContent, SheetHorizontalScroller, LayersSheetButton } from "@shared/features/StudioChrome";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { getTrackingDataForSelection } from "@utilities";
import { JoystickButton } from "../Joystick";
import { RotateSheetButton } from "../RotateSheetButton/RotateSheetButton";

interface ItemReferenceContentProps {
    /** Action to open the joystick */
    openJoystick: () => void;
    /** Action to open the arrange tool */
    openArrangeTools: () => void;
    /** Action to open the rotate slider */
    openRotateSlider: () => void;
}

export function ItemReferenceSheetContentButtons({
    openJoystick,
    openArrangeTools,
    openRotateSlider
}: ItemReferenceContentProps) {
    const selection = useSelection();
    const designer = useDesigner();
    const canvas = useActiveCanvas();

    if (!designer || selection.length !== 1) {
        return null;
    }

    return (
        <>
            <SheetContent className="itemreference-sheet-content">
                <SheetHorizontalScroller>
                    <DeleteTool />
                    <RotateSheetButton onClick={openRotateSlider} />
                    <JoystickButton onClick={openJoystick} />
                    <LayersSheetButton
                        onClick={() => {
                            designer &&
                                designer.eventBus.trigger(
                                    STUDIO_TRACKING_EVENTS.CLICK_ARRANGE,
                                    getTrackingDataForSelection(selection)
                                );
                            openArrangeTools();
                        }}
                        isDisabled={!isLayersToolCompatible(designer, selection, canvas)}
                    />
                    <DuplicateTool alwaysShow />
                </SheetHorizontalScroller>
            </SheetContent>
        </>
    );
}

ItemReferenceSheetContentButtons.displayName = "ItemReferenceSheetContentButtons";
