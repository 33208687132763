import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { StrokeWidthSelector } from "../../dropdowns/StrokeWidthSelector";
import "./strokeWidthSheet.scss";

const messages = defineMessages({
    strokeWidthTitleText: {
        id: "easel.ui.strokeWidthSheetContent.strokeWidthTitleText",
        defaultMessage: "Choose Stroke Width",
        description: {
            note: "Display at the header of the stroke width sheet for mobile"
        }
    }
});

interface StrokeWidthSheetContentProps {
    /** Action the back button should take */
    onBackClick: () => void;
}

/**
 * This Sheet is responsible for selecting font size
 */
export function StrokeWidthSheetContent({ onBackClick }: StrokeWidthSheetContentProps) {
    const { t } = useTranslationSSR();

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(messages.strokeWidthTitleText.id)} />
            <SheetContent className="stroke-width-sheet-content">
                <StrokeWidthSelector expandable={false} className="sheet-stroke-width-selector" />
            </SheetContent>
        </>
    );
}
StrokeWidthSheetContent.displayName = "StrokeWidthSheetContent";
