import React from "react";
import { Button } from "@shared/features/StudioChrome";
import { ColorIconType } from "@presentational";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { getIcon } from "./getIcon";
import "./styles/colorPickerButton.scss";

const messages = defineMessages({
    colorPickerButtonAriaLabel: {
        id: "easel.components.colorPickerButtonAriaLabel",
        defaultMessage: "Color Picker",
        description: {
            note: "Label for a button that allows for changing the color"
        }
    },
    buttonTitle: { id: "easel.designerSuite.colorPickerButton.title", defaultMessage: "Color", description: {} }
});
interface ColorPickerButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    /** Override the default title */
    title?: string;
    /** For opening up the layers tool panel */
    onClick: () => void;
    /**
     * Enum representing which color icon will be used
     * @default TextColorIcon
     */
    icon?: ColorIconType;
    /**
     * Is the button disabled
     * @default false
     */
    isDisabled?: boolean;
    /** Whether the button should return the icon, text, or both  */
    displayType: "iconOnly" | "textOnly" | "both";
}

export function ColorPickerButton({
    className,
    title,
    onClick,
    icon = ColorIconType.TextColorIcon,
    isDisabled,
    displayType,
    ...restOfProps
}: ColorPickerButtonProps) {
    const { t } = useTranslationSSR();
    const label = title || t(messages.buttonTitle.id);
    const colorIcon = getIcon(icon);

    return (
        <Button
            className="color-picker-button easel-color-picker-button"
            onClick={onClick}
            title={t(messages.colorPickerButtonAriaLabel.id)}
            isDisabled={isDisabled}
            {...restOfProps}
        >
            {displayType === "iconOnly" && <div className="dcl-ctx-button-label">{colorIcon}</div>}
            {displayType === "both" && colorIcon}
            {(displayType === "textOnly" || displayType === "both") && (
                <div className="dcl-ctx-button-label">{label}</div>
            )}
        </Button>
    );
}
ColorPickerButton.displayName = "ColorPickerButton";
