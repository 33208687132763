import { useDesigner, useFonts, useSelection } from "@designer-suite";
import { wordArtFilter } from "@utilities";
import { getSelectedFont } from "./getSelectedFont";

const listenEvents = "model:change:data model:change:content";

export function useSelectedFonts() {
    const designer = useDesigner();
    const selection = useSelection(listenEvents);
    const fonts = useFonts();
    const { wordArtItems, otherItems } = wordArtFilter(designer, selection);
    const selectedFontFamily = getSelectedFont([...wordArtItems, ...otherItems]);

    return { wordArtItems, otherItems, selectedFontFamily, fonts };
}
