import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { updateSelectedItems, updateTableAlignment } from "@utilities";
import { alignmentMessages } from "@shared/features/ContextualToolbar";
import { TextAlignOptionGroup } from "./TextAlignOptionGroup";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelection } from "../../designer/useSelection";
import { useTableSelectionFilter } from "../../hooks/useTableSelectionFilter";
import type { Designer } from "../../@types/designer";

function getHorizontalAlignment(designer: Designer, tableItems: TableItem[]) {
    const selectedAlignments = tableItems.reduce<Alignment[]>((acc, curr) => {
        const results = [...acc];
        curr.data.columns.forEach(column => {
            if (!results.includes(column.alignment)) {
                results.push(column.alignment);
            }
        });
        return results;
    }, []);
    return selectedAlignments.length === 1 ? selectedAlignments[0] : "";
}

const listenEvents = "model:change:data";
interface AlignmentToolSheetContentProps /* extends Props */ {
    /** Action the back button should take */
    onBackClick: () => void;
}
/**
 * This Sheet is responsible for showing the alignment tools for an entire table in a sheet
 */
export function TableAlignmentToolSheetContent({ onBackClick, ...restOfProps }: AlignmentToolSheetContentProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection(listenEvents);
    const { tableItems, otherItems } = useTableSelectionFilter(selection);
    const onChange = React.useCallback(
        alignment => updateSelectedItems(designer, selection, updateTableAlignment(alignment)),
        [designer, selection]
    );
    // We only want to do this for tables only
    if (!designer || !tableItems.length || otherItems.length) {
        return null;
    }

    const selectedAlignment = getHorizontalAlignment(designer, tableItems);

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(alignmentMessages.align.id)} />
            <SheetContent className="sheet-vertical-selection-set">
                <TextAlignOptionGroup onChange={onChange} alignment={selectedAlignment} {...restOfProps} />
            </SheetContent>
        </>
    );
}
TableAlignmentToolSheetContent.displayName = "TableAlignmentToolSheetContent";
