import React from "react";
import { Button, ListIcon } from "@shared/features/StudioChrome";

import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useDecorationTechnology } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTrackEvents } from "@shared/features/Tracking";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";

const messages = defineMessages({
    listButtonTitle: {
        id: "easel.ui.text.listbutton.title",
        defaultMessage: "List",
        description: {
            note: "Text in the mobile tool sheet that opens tools for adding list to text"
        }
    }
});

interface TextListSheetButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    /** Whether the button should return the the icon
     * @default false
     */
    showIcon?: boolean;
}

/**
 * This button is responsible for opening the list tools
 */
export function TextListSheetButton({ className = "tool-icon", showIcon, ...restOfProps }: TextListSheetButtonProps) {
    const { t } = useTranslationSSR();
    const decorationTechnology = useDecorationTechnology();

    const { trackEvent } = useTrackEvents();
    const { setCurrentActiveDialog } = useActiveDialog();

    if (decorationTechnology === DecorationTechnologiesSimple.EMBROIDERY) {
        return null;
    }

    return (
        <Button
            onClick={() => {
                trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_LIST });
                setCurrentActiveDialog(DialogType.ListTools);
            }}
            className={className}
            {...restOfProps}
        >
            {showIcon && <ListIcon aria-hidden={true} />}
            <div className="dcl-ctx-button-label">{t(messages.listButtonTitle.id)}</div>
        </Button>
    );
}
TextListSheetButton.displayName = "TextListSheetButton";
