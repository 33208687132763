import React from "react";

import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { PremiumFinishIcon, Button } from "@shared/features/StudioChrome";

const messages = defineMessages({
    buttonTitle: {
        id: "easel.designerSuite.premiumFinishButton.title",
        defaultMessage: "Premium Finish",
        description: {}
    }
});

interface Props {
    /**
     * Whether or not the button is selected (eg toggled on)
     * @default false
     */
    isSelected?: boolean;
    /**
     * Whether a spinner should be shown
     * @default false
     */
    isLoading?: boolean;
    /**
     * Whether or not the button is disabled
     * @default false
     */
    isDisabled?: boolean;
    /**
     * Click handler
     */
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    /**
     * For overriding styles
     */
    className?: string;
}

export function PremiumFinishButton({ isLoading = false, ...props }: Props) {
    const { t } = useTranslationSSR();
    return (
        <Button
            {...props}
            isLoading={isLoading}
            aria-label={t(messages.buttonTitle.id)}
            title={t(messages.buttonTitle.id)}
        >
            <PremiumFinishIcon />
        </Button>
    );
}
PremiumFinishButton.displayName = "PremiumFinishButton";
