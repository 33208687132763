// This code has been generated via svgr
/* eslint-disable max-len */
import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
    className?: string;
}

export function ListIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} />
            <circle cx={2} cy={12} r={1} fill="currentColor" />
            <circle cx={2} cy={8} r={1} fill="currentColor" />
            <circle cx={2} cy={4} r={1} fill="currentColor" />
            <path d="M6 12H14" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M6 8H14" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
            <path d="M6 4H14" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}
ListIcon.displayName = "ListIcon";
