import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { JoystickPanel } from "./JoystickPanel";

const messages = defineMessages({
    joystickToolText: {
        id: "easel.ui.joystickToolbarButton.joystickToolText",
        defaultMessage: "Move",
        description: {
            note: "Display text for the joystick button in the toolbar"
        }
    }
});

interface JoystickSheetContentProps {
    /** Action the back button should take */
    onBackClick: () => void;
}
/**
 * This Sheet is responsible for showing joystick tool in a sheet
 */
export function JoystickSheetContent({ onBackClick }: JoystickSheetContentProps) {
    const { t } = useTranslationSSR();

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(messages.joystickToolText.id)} />
            <SheetContent className="joystick-sheet-content">
                <JoystickPanel />
            </SheetContent>
        </>
    );
}
JoystickSheetContent.displayName = "JoystickSheetContent";
