/**
 * Check whether the selection is compatible with the premium finish
 *
 * @param {Designer} designer - reference to the cimpress designer object
 * @param {ItemSelection} selection - selection to filter
 * @param {Canvas} canvas - current canvas
 * @returns {boolean} true if selection is compatible, false otherwise
 */
import { selectedItemsAreOfTypes } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import type { Designer, ItemSelection } from "../../@types/designer";
import { getPremiumFinishes } from "../../util";

export function isWordArtPremiumFinishCompatible(
    designer: Designer | undefined,
    canvas: Canvas | undefined,
    selection: ItemSelection
) {
    return (
        designer &&
        canvas &&
        selection &&
        selection.length >= 1 &&
        getPremiumFinishes(canvas).length !== 0 &&
        selectedItemsAreOfTypes(selection, [ItemTypes.WORDART])
    );
}
