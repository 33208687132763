import { useEffect, useState } from "react";
import { useActiveCanvas, useDesigner } from "@designer-suite";

/**
 * A hook that provides dimensions of the canvas in mm
 *
 * @returns {
 *   dimensions: { height: number, width: number } - an object with mm dimensions
 * }
 */
export function useActiveCanvasDimensions() {
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

    useEffect(() => {
        if (!designer || !canvas) return;
        const updatedDimensions = canvas.mmDimensions;
        setDimensions(updatedDimensions);
    }, [designer, canvas]);

    return dimensions;
}
