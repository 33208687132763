import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import classNames from "classnames";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelection } from "../../designer/useSelection";
import { StudioFiveEditShapeContainer } from "../../ToolbarComponents/Shapes/StudioFiveEditShapeContainer";
import * as styles from "./EditShapeSheetContent.module.scss";

const messages = defineMessages({
    title: {
        id: "easel.ui.EditShapeSheetContent.title",
        defaultMessage: "Edit Shape",
        description: {
            note: "Display at the header of the edit shape sheet for mobile"
        }
    }
});

interface Props {
    /** Action the back button should take */
    onBackClick: () => void;
}
/**
 * This Sheet is responsible for showing edit shape tool in a sheet
 */
export function EditShapeSheetContent({ onBackClick }: Props) {
    const { t } = useTranslationSSR();

    const designer = useDesigner();
    const selection = useSelection();

    if (!designer || selection.length !== 1) {
        return null;
    }

    const shape = selection[0];
    if (shape.itemType !== ItemTypes.SHAPE) {
        return null;
    }

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(messages.title.id)} />
            <SheetContent
                className={classNames(
                    "sheet-vertical-selection-set",
                    "editshape-sheet-content-container",
                    styles.editshapeSheetContentContainer
                )}
            >
                <StudioFiveEditShapeContainer
                    shape={shape as ShapeItem}
                    className={classNames("edit-shape-selector", styles.editShapeSelector)}
                />
            </SheetContent>
        </>
    );
}
EditShapeSheetContent.displayName = "EditShapeSheetContent";
