import React from "react";
import { ColorIconType, ColorPickerButton, EditShapeButton } from "@presentational";
import { LayersSheetButton, SheetContent, SheetHorizontalScroller } from "@shared/features/StudioChrome";
import {
    DeleteTool,
    LockTool,
    useSelection,
    useDesigner,
    isLayersToolCompatible,
    useActiveCanvas
} from "@designer-suite";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { getTrackingDataForSelection } from "@utilities";
import { JoystickButton } from "../Joystick";
import { getIsFillColorCompatible, getIsStrokeColorCompatible } from "../../color/colorUtil";
import { StrokeWidthButton } from "../StrokeWidth/StrokeWidthButton";
import { RotateSheetButton } from "../RotateSheetButton/RotateSheetButton";
import "./shapeSheetButtons.scss";

interface ShapeSheetContentProps {
    /** Action to open the joystick */
    openJoystick: () => void;
    /** Action to open the arrange tools */
    openArrangeTools: () => void;
    /** Action to open the fill color picker */
    openFillColorPicker: () => void;
    /** Action to open the stroke color picker */
    openStrokeColorPicker: () => void;
    /** Action to open the stroke width selector */
    openStrokeWidthSelector: () => void;
    /** Action to open the stroke width selector */
    openEditShape: () => void;
    /** Action to open the rotate slider */
    openRotateSlider: () => void;
}

export function ShapeSheetContentButtons({
    openJoystick,
    openArrangeTools,
    openFillColorPicker,
    openStrokeColorPicker,
    openStrokeWidthSelector,
    openEditShape,
    openRotateSlider
}: ShapeSheetContentProps) {
    const designer = useDesigner();
    const selection = useSelection();
    const canvas = useActiveCanvas();

    return (
        <SheetContent className="shape-sheet-button-container">
            <SheetHorizontalScroller>
                <div className="shape-sheet-colorpicker-group">
                    <ColorPickerButton
                        icon={ColorIconType.ShapeFillColorIcon}
                        displayType="iconOnly"
                        isDisabled={!getIsFillColorCompatible(designer, selection)}
                        onClick={openFillColorPicker}
                    />
                    <ColorPickerButton
                        icon={ColorIconType.StrokeColorIcon}
                        displayType="iconOnly"
                        isDisabled={!getIsStrokeColorCompatible(designer, selection)}
                        onClick={openStrokeColorPicker}
                    />
                </div>
                <StrokeWidthButton showDownArrow onClick={openStrokeWidthSelector} />
                <DeleteTool showIcon />
                <LayersSheetButton
                    showIcon
                    onClick={() => {
                        designer &&
                            designer.eventBus.trigger(
                                STUDIO_TRACKING_EVENTS.CLICK_ARRANGE,
                                getTrackingDataForSelection(selection)
                            );
                        openArrangeTools();
                    }}
                    isDisabled={!isLayersToolCompatible(designer, selection, canvas)}
                />
                <EditShapeButton showIcon onClick={openEditShape} />
                <RotateSheetButton onClick={openRotateSlider} />
                <LockTool showIcon />
                <JoystickButton showIcon onClick={openJoystick} />
            </SheetHorizontalScroller>
        </SheetContent>
    );
}

ShapeSheetContentButtons.displayName = "ShapeSheetContentButtons";
