import React, { useState, FormEvent } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Typography, Button, StandardForm } from "@vp/swan";
import { useDesigner } from "@designer-suite";
import { QRCodeURLInput, QRCodeMessages as messages } from "@shared/features/QRCodes";
import { SheetContent, StandardSheetHeader } from "@shared/features/StudioChrome";
import { updateQRCodeItemData } from "@utilities";
import { useIsFlagEnabled } from "@shared/features/Flags";
import "./QRCodeEditSheetContent.scss";

interface QRCodeEditSheetContentProps {
    /** Action the back button should take */
    onBackClick: () => void;
    /** Pass in the QR Code item */
    qrCodeItem: QRCodeItem;
}

export function QRCodeEditSheetContent(props: QRCodeEditSheetContentProps) {
    const { onBackClick, qrCodeItem } = props;
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const [url, setUrl] = useState<string>(qrCodeItem.data.url);
    const [error, setError] = useState(false);
    const textImageSelection = useIsFlagEnabled("textImageSelection");

    const onSubmit = (e: FormEvent<HTMLElement>) => {
        if (!designer) {
            return;
        }

        e.preventDefault();

        const newData = { url, processedUrl: url };

        designer.api.design.updateItem<QRCodeItem>(qrCodeItem.id, mutableItem => {
            updateQRCodeItemData(mutableItem, newData);
        });

        onBackClick();
    };

    const onClose = () => {
        onBackClick();
        setError(false);
    };

    if (!designer) {
        return null;
    }

    return (
        <>
            <SheetContent className="qrcodes-sheet-content">
                <StandardSheetHeader
                    className="sheet-panel-header"
                    onBackClick={onClose}
                    onCloseClick={onClose}
                    sheetTitle={
                        textImageSelection ? t(messages.editQrCodeSheetTitle.id) : t(messages.editSheetTitle.id)
                    }
                />
                {textImageSelection && (
                    <Typography textColor="dark-grey" fontSize="-1" pl={4}>
                        {t(messages.editQRInstructions.id)}
                    </Typography>
                )}
                <StandardForm className="easel-qrcode-dialog-content-container" onSubmit={onSubmit}>
                    <div className="easel-qrcode-dialog-input">
                        <QRCodeURLInput url={url} onUrlChange={setUrl} error={error} setError={setError} />
                    </div>
                    <Button
                        disabled={error || !url}
                        title={t(messages.done.id)}
                        skin="primary"
                        width="full-width"
                        size="mini"
                        type="submit"
                    >
                        {t(messages.done.id)}
                    </Button>
                </StandardForm>
            </SheetContent>
        </>
    );
}
QRCodeEditSheetContent.displayName = "QRCodeEditSheetContent";
