import React, { useCallback, useEffect } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { LayersToolSheetContent } from "../LayersTool/LayersToolSheetContent";
import { JoystickSheetContent } from "../Joystick";
import { ImageSheetContentButtons } from "./ImageSheetContentButtons";
import { ColorPickerSheetContent, ColorType } from "../ColorPicker/ColorPickerSheetContent";
import { PremiumFinishSheetContent } from "../PremiumFinish/PremiumFinishSheetContent";
import { RotateButtonSheetContent } from "../RotateSheetButton/RotateSheetButtonContent";

const messages = defineMessages({
    iconColorTitle: {
        id: "easel.ui.sheets.imageSheetContentContainer.title",
        defaultMessage: "Icon color"
    }
});

export function ImageSheetContentContainer() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { t } = useTranslationSSR();

    const openImageTools = useCallback(() => setCurrentActiveDialog(DialogType.ImageTools), [setCurrentActiveDialog]);
    const openSingleColorTools = useCallback(
        () => setCurrentActiveDialog(DialogType.SingleColorImageColorPicker),
        [setCurrentActiveDialog]
    );
    const openJoystick = useCallback(() => setCurrentActiveDialog(DialogType.JoystickTools), [setCurrentActiveDialog]);
    const openArrangeTools = useCallback(
        () => setCurrentActiveDialog(DialogType.ArrangeTools),
        [setCurrentActiveDialog]
    );
    const openRotateSlider = useCallback(
        () => setCurrentActiveDialog(DialogType.RotateTools),
        [setCurrentActiveDialog]
    );
    const openColorTools = useCallback(() => setCurrentActiveDialog(DialogType.ColorTools), [setCurrentActiveDialog]);

    useEffect(() => {
        setCurrentActiveDialog(DialogType.ImageTools);
    }, [setCurrentActiveDialog]);

    // eslint-disable-next-line react/display-name
    function getContent() {
        switch (currentActiveDialog) {
            case DialogType.JoystickTools:
                return <JoystickSheetContent onBackClick={openImageTools} />;
            case DialogType.ArrangeTools:
                return <LayersToolSheetContent onBackClick={openImageTools} />;
            case DialogType.ColorTools:
                return <ColorPickerSheetContent colorType={ColorType.ImageColor} onBackClick={openImageTools} />;
            case DialogType.SingleColorImageColorPicker:
                return (
                    <ColorPickerSheetContent
                        colorType={ColorType.SingleColorImageColor}
                        onBackClick={openImageTools}
                        title={t(messages.iconColorTitle.id)}
                    />
                );
            case DialogType.PremiumFinishes:
                return <PremiumFinishSheetContent onBackClick={openImageTools} />;
            case DialogType.RotateTools:
                return <RotateButtonSheetContent onBackClick={openImageTools} />;
            case DialogType.ImageTools:
                return (
                    <ImageSheetContentButtons
                        openJoystick={openJoystick}
                        openArrangeTools={openArrangeTools}
                        openColorTools={openColorTools}
                        openSingleColorTools={openSingleColorTools}
                        openRotateSlider={openRotateSlider}
                    />
                );
            default:
                return null;
        }
    }

    return <React.Fragment>{getContent()}</React.Fragment>;
}

ImageSheetContentContainer.displayName = "ImageSheetContentContainer";
