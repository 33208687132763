import React from "react";
import { LockTool, useSelection, useDesigner, DeleteTool } from "@designer-suite";
import { SheetContent, SheetHorizontalScroller } from "@shared/features/StudioChrome";
import { ReplacePlaceholderQRCodeTool } from "@five/components/QRCodes/ReplacePlaceholderQRCodeTool";
import { ReplaceImageTool } from "../../ToolbarComponents/ReplaceImages/ReplaceImageTool";
import { JoystickButton } from "../Joystick";
import "../Images/imageSheet.scss";

interface ImagePlaceholderSheetContentProps {
    /** Action to open the joystick */
    openJoystick: () => void;
}

export function ImagePlaceholderSheetContentButtons({ openJoystick }: ImagePlaceholderSheetContentProps) {
    const selection = useSelection();
    const designer = useDesigner();

    if (!designer || selection.length !== 1) {
        return null;
    }

    return (
        <SheetContent className="image-sheet-content">
            <SheetHorizontalScroller>
                <DeleteTool showIcon />
                <ReplaceImageTool showIcon isMobile />
                <ReplacePlaceholderQRCodeTool showIcon />
                <JoystickButton showIcon onClick={() => openJoystick()} />
                <LockTool showIcon />
            </SheetHorizontalScroller>
        </SheetContent>
    );
}
ImagePlaceholderSheetContentButtons.displayName = "ImagePlaceholderSheetContentButtons";
