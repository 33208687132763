import React, { useCallback, useEffect } from "react";
import { useSelection } from "@designer-suite";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { LayersToolSheetContent } from "../LayersTool/LayersToolSheetContent";
import { JoystickSheetContent } from "../Joystick";
import { QRCodesSheetContentButtons } from "./QRCodesSheetContentButtons";
import { QRCodeEditSheetContent } from "../QRCodeEdit";
import { RotateButtonSheetContent } from "../RotateSheetButton/RotateSheetButtonContent";

export function QRCodesSheetContentContainer() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const selection = useSelection();
    const openQRCodesTools = useCallback(
        () => setCurrentActiveDialog(DialogType.QRCodesTools),
        [setCurrentActiveDialog]
    );
    const openQRCodeEditor = useCallback(
        () => setCurrentActiveDialog(DialogType.QRCodesEditor),
        [setCurrentActiveDialog]
    );
    const openJoystick = useCallback(() => setCurrentActiveDialog(DialogType.JoystickTools), [setCurrentActiveDialog]);
    const openArrangeTools = useCallback(
        () => setCurrentActiveDialog(DialogType.ArrangeTools),
        [setCurrentActiveDialog]
    );
    const openRotateSlider = useCallback(
        () => setCurrentActiveDialog(DialogType.RotateTools),
        [setCurrentActiveDialog]
    );
    useEffect(() => {
        setCurrentActiveDialog(DialogType.QRCodesTools);
    }, [setCurrentActiveDialog]);

    if (!selection || selection.length !== 1) {
        return null;
    }

    return (
        <React.Fragment>
            {currentActiveDialog === DialogType.JoystickTools && (
                <JoystickSheetContent onBackClick={openQRCodesTools} />
            )}
            {currentActiveDialog === DialogType.ArrangeTools && (
                <LayersToolSheetContent onBackClick={openQRCodesTools} />
            )}
            {currentActiveDialog === DialogType.RotateTools && (
                <RotateButtonSheetContent onBackClick={openQRCodesTools} />
            )}
            {currentActiveDialog === DialogType.QRCodesTools && (
                <QRCodesSheetContentButtons
                    openJoystick={openJoystick}
                    openArrangeTools={openArrangeTools}
                    openQRCodeEditor={openQRCodeEditor}
                    openRotateSlider={openRotateSlider}
                />
            )}
            {currentActiveDialog === DialogType.QRCodesEditor && (
                <QRCodeEditSheetContent onBackClick={openQRCodesTools} qrCodeItem={selection[0] as QRCodeItem} />
            )}
        </React.Fragment>
    );
}

QRCodesSheetContentContainer.displayName = "QRCodesSheetContentContainer";
