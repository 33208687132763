import React, { useEffect, useCallback } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { LayersToolSheetContent } from "../LayersTool/LayersToolSheetContent";
import { JoystickSheetContent } from "../Joystick";
import { CurveToolSheetContent } from "../../ToolbarComponents/CurveTool/CurveToolSheetContent";
import { WordArtSheetContentButtons } from "./WordArtSheetContentButtons";
import { ColorPickerSheetContent, ColorType } from "../ColorPicker/ColorPickerSheetContent";
import { FontSelectorSheetContent } from "../FontSelector/FontSelectorSheetContent";
import { PremiumFinishSheetContent } from "../PremiumFinish/PremiumFinishSheetContent";
import { RotateButtonSheetContent } from "../RotateSheetButton/RotateSheetButtonContent";

const messages = defineMessages({
    wordartColorTitle: {
        id: "easel.ui.sheets.wordArtSheetContentContainer.title",
        defaultMessage: "Text color"
    }
});

export function WordArtSheetContentContainer() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { t } = useTranslationSSR();

    const openWordArtTools = useCallback(
        () => setCurrentActiveDialog(DialogType.WordArtTools),
        [setCurrentActiveDialog]
    );
    const openJoystick = useCallback(() => setCurrentActiveDialog(DialogType.JoystickTools), [setCurrentActiveDialog]);
    const openArrangeTools = useCallback(
        () => setCurrentActiveDialog(DialogType.ArrangeTools),
        [setCurrentActiveDialog]
    );
    const openCurveTools = useCallback(() => setCurrentActiveDialog(DialogType.CurveTools), [setCurrentActiveDialog]);
    const openWordArtColorPicker = useCallback(
        () => setCurrentActiveDialog(DialogType.WordArtColorPicker),
        [setCurrentActiveDialog]
    );
    const openFontSelectorTools = useCallback(
        () => setCurrentActiveDialog(DialogType.FontSelectorTools),
        [setCurrentActiveDialog]
    );
    const openRotateSlider = useCallback(
        () => setCurrentActiveDialog(DialogType.RotateTools),
        [setCurrentActiveDialog]
    );
    const openTextInput = useCallback(() => setCurrentActiveDialog(DialogType.TextInput), [setCurrentActiveDialog]);

    useEffect(() => {
        if (currentActiveDialog === DialogType.None) {
            setCurrentActiveDialog(DialogType.WordArtTools);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCurrentActiveDialog]);

    return (
        <React.Fragment>
            {currentActiveDialog === DialogType.JoystickTools && (
                <JoystickSheetContent onBackClick={openWordArtTools} />
            )}
            {currentActiveDialog === DialogType.ArrangeTools && (
                <LayersToolSheetContent onBackClick={openWordArtTools} />
            )}
            {currentActiveDialog === DialogType.CurveTools && <CurveToolSheetContent onBackClick={openWordArtTools} />}
            {currentActiveDialog === DialogType.PremiumFinishes && (
                <PremiumFinishSheetContent onBackClick={openWordArtTools} />
            )}
            {currentActiveDialog === DialogType.RotateTools && (
                <RotateButtonSheetContent onBackClick={openWordArtTools} />
            )}
            {currentActiveDialog === DialogType.WordArtTools && (
                <WordArtSheetContentButtons
                    openJoystick={openJoystick}
                    openArrangeTools={openArrangeTools}
                    openRotateSlider={openRotateSlider}
                    openCurveTools={openCurveTools}
                    openWordArtColorPicker={openWordArtColorPicker}
                    openFontSelectorTools={openFontSelectorTools}
                    openTextInput={openTextInput}
                />
            )}
            {currentActiveDialog === DialogType.TextInput && (
                <WordArtSheetContentButtons
                    openJoystick={openJoystick}
                    openArrangeTools={openArrangeTools}
                    openRotateSlider={openRotateSlider}
                    openCurveTools={openCurveTools}
                    openWordArtColorPicker={openWordArtColorPicker}
                    openFontSelectorTools={openFontSelectorTools}
                    openTextInput={openTextInput}
                />
            )}
            {currentActiveDialog === DialogType.WordArtColorPicker && (
                <ColorPickerSheetContent
                    colorType={ColorType.WordArtAndTextColor}
                    onBackClick={openWordArtTools}
                    title={t(messages.wordartColorTitle.id)}
                />
            )}
            {currentActiveDialog === DialogType.FontSelectorTools && (
                <FontSelectorSheetContent onBackClick={openWordArtTools} />
            )}
        </React.Fragment>
    );
}

WordArtSheetContentContainer.displayName = "WordArtSheetContentContainer";
