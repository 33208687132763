import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button, EditTextIcon } from "@shared/features/StudioChrome";

import { useSelection, useDesigner, focusMobileDock } from "@designer-suite";
import { getTrackingDataForSelection } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";

interface EditTextButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    /** For initiating the start of the move */
    onClick: () => void;

    /** Whether the button should return the the icon
     * @default false
     */
    showIcon?: boolean;
}

const messages = defineMessages({
    editTextButton: {
        id: "easel.ui.toolsheet.editTextButton",
        defaultMessage: "Edit text",
        description: {
            note: "Button in mobile sheet so that user can open the editing experience for text"
        }
    }
});

/**
 * This button displays the Edit Text icon with text
 */
export function EditTextButton({ className = "tool-icon", onClick, showIcon, ...restOfProps }: EditTextButtonProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection();

    return (
        <Button
            onClick={() => {
                designer &&
                    designer.eventBus.trigger(
                        STUDIO_TRACKING_EVENTS.CLICK_EDIT_TEXT,
                        getTrackingDataForSelection(selection)
                    );
                onClick();
                focusMobileDock();
            }}
            title={t(messages.editTextButton.id)}
            className={className}
            {...restOfProps}
        >
            {showIcon && <EditTextIcon />}
            <div className="dcl-ctx-button-label">{t(messages.editTextButton.id)}</div>
        </Button>
    );
}
EditTextButton.displayName = "EditTextButton";
