import React from "react";
import classnames from "classnames";
import {
    DuplicateTool,
    useSelection,
    useDesigner,
    useActiveCanvas,
    DeleteTool,
    isLayersToolCompatible
} from "@designer-suite";
import { getTrackingDataForSelection, wordArtFilter } from "@utilities";
import { ColorPickerButton, ColorIconType } from "@presentational";
import {
    LayersSheetButton,
    SheetContent,
    SheetFontSelectionContainer,
    SheetFormattingContainer,
    SheetHorizontalDivider,
    SheetHorizontalScroller,
    SheetTextStylesContainer
} from "@shared/features/StudioChrome";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { JoystickButton } from "../Joystick";
import { WordArtAndTextBoldButton } from "../../ToolbarComponents/Buttons/WordArtAndTextBoldButton";
import { WordArtAndTextItalicButton } from "../../ToolbarComponents/Buttons/WordArtAndTextItalicButton";
import { CurveToolButton } from "../../ToolbarComponents/CurveTool/CurveToolButton";
import { isWordArtPremiumFinishCompatible } from "./isWordArtPremiumFinishCompatible";
import { FontButton } from "../FontSelector/FontButton";
import { useMobileText } from "../../MobileTextEditor/MobileTextProvider";
import { PremiumFinishSheetButton } from "../PremiumFinish/PremiumFinishSheetButton";
import WordArtFontSizeButtonContainer from "../../ToolbarComponents/WordArtFontSize/WordArtFontSizeButtonContainer";
import { RotateSheetButton } from "../RotateSheetButton/RotateSheetButton";
import { EditTextButton } from "../EditTextButton";
import {
    getIsTextColorPickerCompatible,
    shouldTextColorPickerBeDisabled
} from "../../ToolbarComponents/Buttons/WordArtAndTextColorPicker";

interface WordArtContentProps {
    /** Action to open the joystick */
    openJoystick: () => void;
    /** Action to open the arrange tool */
    openArrangeTools: () => void;
    /** Action to open the curve tool */
    openCurveTools: () => void;
    /** Action to open the word art color picker */
    openWordArtColorPicker: () => void;
    /** Action to open the font selector tool */
    openFontSelectorTools: () => void;
    /** Action to open the rotate slider */
    openRotateSlider: () => void;
    /** Action to open the text input */
    openTextInput: () => void;
}

export function WordArtSheetContentButtons({
    openJoystick,
    openArrangeTools,
    openCurveTools,
    openWordArtColorPicker,
    openFontSelectorTools,
    openRotateSlider,
    openTextInput
}: WordArtContentProps) {
    const selection = useSelection();
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const showPremiumFinishToggle = isWordArtPremiumFinishCompatible(designer, canvas, selection);
    const { isTextInputFocused, animatingOut } = useMobileText();
    const { otherItems } = wordArtFilter(designer, selection);

    if (!designer || selection.length !== 1) {
        return null;
    }

    return (
        <>
            {!isTextInputFocused && (
                <SheetContent
                    className={classnames("wordart-sheet-content", { hidden: isTextInputFocused || animatingOut })}
                >
                    <SheetFormattingContainer>
                        <ColorPickerButton
                            icon={ColorIconType.TextColorIcon}
                            displayType="iconOnly"
                            onClick={openWordArtColorPicker}
                            isDisabled={
                                !getIsTextColorPickerCompatible(designer, otherItems) ||
                                shouldTextColorPickerBeDisabled(selection, canvas)
                            }
                        />
                        <SheetTextStylesContainer>
                            <WordArtAndTextBoldButton />
                            <WordArtAndTextItalicButton />
                        </SheetTextStylesContainer>
                    </SheetFormattingContainer>
                    <SheetHorizontalDivider />
                    <SheetFontSelectionContainer>
                        <FontButton onClick={openFontSelectorTools} />
                        <WordArtFontSizeButtonContainer expandable={false} className="sheet-font-size-selector" />
                    </SheetFontSelectionContainer>
                    <SheetHorizontalDivider />
                    <SheetHorizontalScroller reducedHeight>
                        {showPremiumFinishToggle && <PremiumFinishSheetButton showIcon />}
                        <EditTextButton showIcon onClick={openTextInput} />
                        <DeleteTool showIcon />
                        <CurveToolButton showIcon onClick={openCurveTools} />
                        <LayersSheetButton
                            showIcon
                            onClick={() => {
                                designer &&
                                    designer.eventBus.trigger(
                                        STUDIO_TRACKING_EVENTS.CLICK_ARRANGE,
                                        getTrackingDataForSelection(selection)
                                    );
                                openArrangeTools();
                            }}
                            isDisabled={!isLayersToolCompatible(designer, selection, canvas)}
                        />
                        <DuplicateTool showIcon alwaysShow />
                        <RotateSheetButton onClick={openRotateSlider} />
                        <JoystickButton showIcon onClick={openJoystick} />
                    </SheetHorizontalScroller>
                </SheetContent>
            )}
        </>
    );
}

WordArtSheetContentButtons.displayName = "WordArtSheetContentButtons";
