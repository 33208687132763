import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useRecentFontsContext, useCreateRenderFontResultForStudio5, useEnableFontBrowser } from "@presentational";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { teamsPlaceholderFilter, updateSelectedItems, updateTeamsItemData } from "@utilities";
import { useFonts, useSelection, useFontSelectorFontListStudio5 } from "@designer-suite";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { FontSelectorPanel } from "@shared/features/ContextualToolbar";
import { useDesigner } from "../../designer/DesignerProvider";
import { getSelectedFont } from "../FontSelector/getSelectedFont";

const messages = defineMessages({
    fontSelectorTitleText: {
        id: "easel.ui.TeamsFontSelectorSheetContent.fontSelectorTitleText",
        defaultMessage: "Choose Font",
        description: {
            note: "Display at the header of the font selector sheet for mobile"
        }
    }
});

interface FontSelectorSheetContentProps {
    /** Action the back button should take */
    onBackClick: () => void;
}

const listenEvents = "model:change:data";

/**
 * This Sheet is responsible for a font selector in a sheet
 */
export function TeamsFontSelectorSheetContent({ onBackClick }: FontSelectorSheetContentProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const fonts = useFonts();
    const selection = useSelection(listenEvents);
    const { teamsPlaceholderItems, otherItems } = teamsPlaceholderFilter(selection);
    const selectedFontFamily = getSelectedFont(teamsPlaceholderItems);
    const enableFontBrowser = useEnableFontBrowser();
    const { recentFonts, updateLastSelectedFont } = useRecentFontsContext();

    const combinedFontList = useFontSelectorFontListStudio5({
        fontList: fonts,
        selectedFontFamily,
        useRecentFonts: useRecentFontsContext
    });

    const renderFontResult = useCreateRenderFontResultForStudio5(combinedFontList);

    if (!designer || !teamsPlaceholderItems.length || otherItems.length) {
        return null;
    }

    function onSelectFont(fontFamily: string) {
        updateSelectedItems(designer, teamsPlaceholderItems, teamsPlaceholder => {
            updateTeamsItemData(teamsPlaceholder, { fontFamily });
        });
    }

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(messages.fontSelectorTitleText.id)} />
            <SheetContent className="font-selector-sheet-content">
                <FontSelectorPanel
                    onOpenFontBrowser={() => {
                        designer && designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT_BROWSER);
                    }}
                    enableFontBrowser={enableFontBrowser}
                    selectedFontFamily={selectedFontFamily}
                    allFonts={combinedFontList.map(font => font.family)}
                    recentFonts={recentFonts}
                    onSelectFont={onSelectFont}
                    updateLastSelectedFont={updateLastSelectedFont}
                    renderFontResult={renderFontResult}
                    className="sheet-font-selector"
                />
            </SheetContent>
        </>
    );
}
TeamsFontSelectorSheetContent.displayName = "TeamsFontSelectorSheetContent";
