import React from "react";
import {
    DuplicateTool,
    useSelection,
    useDesigner,
    useActiveCanvas,
    DeleteTool,
    StrikethroughButton,
    UnderlineButton,
    PremiumFinishTextButton as ConnectedPremiumFinishTextButton,
    TextFontSizeSelector,
    isLayersToolCompatible
} from "@designer-suite";
import classnames from "classnames";
import { ColorPickerButton, ColorIconType } from "@presentational";
import {
    LayersSheetButton,
    SheetContent,
    SheetFontSelectionContainer,
    SheetFormattingContainer,
    SheetHorizontalDivider,
    SheetHorizontalScroller,
    SheetTextStylesContainer
} from "@shared/features/StudioChrome";
import { getTrackingDataForSelection, wordArtFilter } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { JoystickButton } from "../Joystick";
import { FontButton } from "../FontSelector/FontButton";
import { AlignmentToolButton } from "../../ToolbarComponents/Alignment/AlignmentToolButton";
import { WordArtAndTextBoldButton } from "../../ToolbarComponents/Buttons/WordArtAndTextBoldButton";
import { WordArtAndTextItalicButton } from "../../ToolbarComponents/Buttons/WordArtAndTextItalicButton";
import { useMobileText } from "../../MobileTextEditor/MobileTextProvider";
import { TextListSheetButton } from "../TextList/TextListSheetButton";
import { PremiumFinishSheetButton } from "../PremiumFinish/PremiumFinishSheetButton";
import { RotateSheetButton } from "../RotateSheetButton/RotateSheetButton";
import { EditTextButton } from "../EditTextButton";
import {
    getIsTextColorPickerCompatible,
    shouldTextColorPickerBeDisabled
} from "../../ToolbarComponents/Buttons/WordArtAndTextColorPicker";
import "./textSheet.scss";

const { getIsCompatible: getIsPremiumFinishCompatible } = ConnectedPremiumFinishTextButton;

interface TextSheetContentProps {
    /** Action to open the joystick */
    openJoystick: () => void;
    /** Action to open the arrange tool */
    openArrangeTools: () => void;
    /** Action to open the text color picker */
    openTextColorPicker: () => void;
    /** Action to open the font selector tool */
    openFontSelectorTools: () => void;
    /** Action to open the text alignment tool */
    openAlignmentTool: () => void;
    /** Action to open the rotate slider */
    openRotateSlider: () => void;
    /** Action to open the text input tool */
    openTextInput: () => void;
}

export function TextSheetContentButtons({
    openJoystick,
    openArrangeTools,
    openTextColorPicker,
    openFontSelectorTools = () => {},
    openAlignmentTool = () => {},
    openRotateSlider,
    openTextInput
}: TextSheetContentProps) {
    const selection = useSelection();
    const designer = useDesigner();
    const canvas = useActiveCanvas();
    const showPremiumFinishToggle = getIsPremiumFinishCompatible(designer, selection, canvas);
    const { isTextInputFocused, animatingOut } = useMobileText();
    const { otherItems } = wordArtFilter(designer, selection);

    if (!designer || selection.length !== 1) {
        return null;
    }

    return (
        <>
            <SheetContent className={classnames("text-sheet-content", { hidden: isTextInputFocused || animatingOut })}>
                <SheetFormattingContainer>
                    <ColorPickerButton
                        icon={ColorIconType.TextColorIcon}
                        onClick={openTextColorPicker}
                        displayType={"iconOnly"}
                        isDisabled={
                            !getIsTextColorPickerCompatible(designer, otherItems) ||
                            shouldTextColorPickerBeDisabled(selection, canvas)
                        }
                        data-testid="color-picker-button"
                    />
                    <SheetTextStylesContainer>
                        <WordArtAndTextBoldButton />
                        <WordArtAndTextItalicButton />
                        <UnderlineButton />
                        <StrikethroughButton />
                    </SheetTextStylesContainer>
                    <div className="text-sheet-right-placeholder" />
                </SheetFormattingContainer>
                <SheetHorizontalDivider />
                <SheetFontSelectionContainer>
                    <FontButton onClick={openFontSelectorTools} data-testid="font-family-button" />
                    <TextFontSizeSelector expandable={false} className="sheet-font-size-selector" />
                </SheetFontSelectionContainer>
                <SheetHorizontalDivider />
                <SheetHorizontalScroller reducedHeight>
                    {showPremiumFinishToggle && <PremiumFinishSheetButton showIcon />}
                    <EditTextButton showIcon onClick={openTextInput} />
                    <DeleteTool showIcon />
                    <TextListSheetButton showIcon />
                    <AlignmentToolButton showIcon onClick={openAlignmentTool} />
                    <LayersSheetButton
                        showIcon
                        onClick={() => {
                            designer &&
                                designer.eventBus.trigger(
                                    STUDIO_TRACKING_EVENTS.CLICK_ARRANGE,
                                    getTrackingDataForSelection(selection)
                                );
                            openArrangeTools();
                        }}
                        isDisabled={!isLayersToolCompatible(designer, selection, canvas)}
                    />
                    <DuplicateTool showIcon alwaysShow />
                    <RotateSheetButton onClick={openRotateSlider} />
                    <JoystickButton showIcon onClick={openJoystick} />
                </SheetHorizontalScroller>
            </SheetContent>
        </>
    );
}

TextSheetContentButtons.displayName = "TextSheetContentButtons";
