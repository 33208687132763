import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button, JoystickIcon } from "@shared/features/StudioChrome";

import { useSelection, useDesigner } from "@designer-suite";
import { getTrackingDataForSelection } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { isJoystickCompatible } from "./isCompatible";

interface JoystickButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    /** For initiating the start of the move */
    onClick: () => void;

    /** Whether the button should return the the icon
     * @default false
     */
    showIcon?: boolean;
}

const messages = defineMessages({
    joystickToolText: {
        id: "easel.ui.joystickToolbarButton.joystickToolText",
        defaultMessage: "Move",
        description: {
            note: "Display text for the joystick button in the toolbar"
        }
    },
    joystickCloseButtonText: {
        id: "easel.ui.joystickToolbarButton.joystickCloseButtonText",
        defaultMessage: "Close",
        description: {
            note: "Display text button that closes the joystick tool"
        }
    }
});

/**
 * This button displays the move icon with text
 */
export function JoystickButton({ className = "tool-icon", onClick, showIcon, ...restOfProps }: JoystickButtonProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection();

    return (
        <Button
            onClick={() => {
                designer &&
                    designer.eventBus.trigger(
                        STUDIO_TRACKING_EVENTS.CLICK_MOVE,
                        getTrackingDataForSelection(selection)
                    );
                onClick();
            }}
            title={t(messages.joystickToolText.id)}
            isDisabled={!isJoystickCompatible(designer, selection)}
            className={className}
            {...restOfProps}
        >
            {showIcon && <JoystickIcon />}
            <div className="dcl-ctx-button-label">{t(messages.joystickToolText.id)}</div>
        </Button>
    );
}
JoystickButton.displayName = "JoystickButton";
