import React, { HTMLProps, useEffect } from "react";
import { useSelection, useDesigner, updateSelectedItems } from "@designer-suite";
import { Direction, JoystickPanelContent } from "@shared/features/Joystick";
import { isJoystickCompatible } from "./isCompatible";
import { useActiveCanvasDimensions } from "../../hooks/useActiveCanvasDimensions";
import type { ItemSelection } from "../../@types/designer";
import "./joystick.scss";

const BaseOffset = 1;
const SizeNormalizer = 1000;
const InitialIntervalDelay = 200;
const SubsequentIntervalDelay = 100;

let moveIntervalId: number;
/**
 * This panel of buttons is responsible for moving the selected item
 * Intended for Mobile only
 */
export function JoystickPanel({ className }: HTMLProps<HTMLDivElement>) {
    const designer = useDesigner();
    const selection = useSelection();
    const { height, width } = useActiveCanvasDimensions();
    const showJoystick = isJoystickCompatible(designer, selection);

    function move(direction: Direction, selectedItems: ItemSelection) {
        if (!designer) return;
        const zoomFactor = designer.documentRepository.getActiveCanvasViewModel().get("zoomFactor");
        // previous calc was BaseOffset / zoomFactor;
        const yOffset = Math.max(((height / SizeNormalizer) * BaseOffset) / zoomFactor, BaseOffset / zoomFactor);
        const xOffset = Math.max(((width / SizeNormalizer) * BaseOffset) / zoomFactor, BaseOffset / zoomFactor);
        let offsetX = 0;
        let offsetY = 0;
        switch (direction) {
            case Direction.Up:
                offsetY = -yOffset;
                break;
            case Direction.Down:
                offsetY = yOffset;
                break;
            case Direction.Left:
                offsetX = -xOffset;
                break;
            case Direction.Right:
                offsetX = xOffset;
                break;
            default:
                break;
        }

        // It would be useful to do this with designer.commandDispatcher.move
        //  as that would get the out of bounds behavior for free (delete item)
        //  currently that aproach errors out when done from here
        // Outofbounds behavior has been deferred as a feature
        // In cimpress-designer - see
        //  - app\core\commands\Move.js
        //  - app\core\util\CommandUtil.js - deleteOutOfBoundsViewModels
        //  - app\core\util\ItemUtil.js - isViewModelOutOfBounds, isViewModelOutOfBoundsWithPosition
        updateSelectedItems(designer, selectedItems, (item: Item) => {
            const { model } = item._itemViewModel;

            model.set("top", model.get("top") + offsetY);
            model.set("left", model.get("left") + offsetX);
        });
    }

    function startMove(direction: Direction) {
        if (typeof window === "undefined") {
            return;
        }
        // setInterval() needs to be called explicitly on the window object in order
        // to prevent TypeScript from incorrectly recognizing it as Node.js's function
        // of the same name. Without the window specifier, a type error would occur,
        // since Node.js's setInterval returns a value with a different type.

        move(direction, selection);
        function delayedMove() {
            clearTimeout(moveIntervalId);
            move(direction, selection);
            // Fire subsequent additional moves with a shorter delay
            //  Makes holding down the button to move seem smoother
            moveIntervalId = window.setInterval(delayedMove, SubsequentIntervalDelay);
        }
        // Fire the first additional move with a longer delay
        //  Helps prevent overly sensitive move on just tapping the button
        moveIntervalId = window.setInterval(delayedMove, InitialIntervalDelay);
    }

    function onMoveEnd() {
        clearTimeout(moveIntervalId);
    }

    useEffect(() => {
        return () => {
            onMoveEnd();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <JoystickPanelContent
            startMove={startMove}
            onMoveEnd={onMoveEnd}
            className={className}
            showJoystick={showJoystick}
        />
    );
}
JoystickPanel.displayName = "JoystickPanel";
