import React, { useCallback, useEffect } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { JoystickSheetContent } from "../Joystick";
import { TextSheetContentButtons } from "./TextSheetContentButtons";
import { FontSelectorSheetContent } from "../FontSelector/FontSelectorSheetContent";
import { LayersToolSheetContent } from "../LayersTool/LayersToolSheetContent";
import { ColorPickerSheetContent, ColorType } from "../ColorPicker/ColorPickerSheetContent";
import { AlignmentToolSheetContent } from "../../ToolbarComponents/Alignment/AlignmentToolSheetContent";
import { TextListSheetContent } from "../TextList/TextListSheetContent";
import { PremiumFinishSheetContent } from "../PremiumFinish/PremiumFinishSheetContent";
import { RotateButtonSheetContent } from "../RotateSheetButton/RotateSheetButtonContent";
import { useMobileText } from "../../MobileTextEditor/MobileTextProvider";

const messages = defineMessages({
    textColorTitle: {
        id: "easel.ui.sheets.textSheetContentContainer.title",
        defaultMessage: "Text color"
    }
});

export function TextSheetContentContainer() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { setIsTextInputFocused } = useMobileText();

    const { t } = useTranslationSSR();

    const openTextTools = useCallback(() => setCurrentActiveDialog(DialogType.TextTools), [setCurrentActiveDialog]);
    const openJoystick = useCallback(() => setCurrentActiveDialog(DialogType.JoystickTools), [setCurrentActiveDialog]);
    const openAlignmentTool = useCallback(() => setCurrentActiveDialog(DialogType.AlignTool), [setCurrentActiveDialog]);
    const openArrangeTools = useCallback(
        () => setCurrentActiveDialog(DialogType.ArrangeTools),
        [setCurrentActiveDialog]
    );
    const openTextColorPicker = useCallback(
        () => setCurrentActiveDialog(DialogType.TextColorPicker),
        [setCurrentActiveDialog]
    );
    const openFontSelectorTools = useCallback(
        () => setCurrentActiveDialog(DialogType.FontSelectorTools),
        [setCurrentActiveDialog]
    );
    const openTextInput = useCallback(() => {
        setIsTextInputFocused(true);
        setCurrentActiveDialog(DialogType.TextInput);
    }, [setCurrentActiveDialog, setIsTextInputFocused]);

    const openRotateSlider = useCallback(
        () => setCurrentActiveDialog(DialogType.RotateTools),
        [setCurrentActiveDialog]
    );

    useEffect(() => {
        if (currentActiveDialog === DialogType.None) {
            setCurrentActiveDialog(DialogType.TextTools);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCurrentActiveDialog]);

    return (
        <>
            {currentActiveDialog === DialogType.AlignTool && <AlignmentToolSheetContent onBackClick={openTextTools} />}
            {currentActiveDialog === DialogType.JoystickTools && <JoystickSheetContent onBackClick={openTextTools} />}
            {currentActiveDialog === DialogType.ArrangeTools && <LayersToolSheetContent onBackClick={openTextTools} />}
            {currentActiveDialog === DialogType.RotateTools && <RotateButtonSheetContent onBackClick={openTextTools} />}
            {currentActiveDialog === DialogType.ListTools && <TextListSheetContent onBackClick={openTextTools} />}
            {currentActiveDialog === DialogType.PremiumFinishes && (
                <PremiumFinishSheetContent onBackClick={openTextTools} />
            )}
            {currentActiveDialog === DialogType.TextTools && (
                <TextSheetContentButtons
                    openAlignmentTool={openAlignmentTool}
                    openJoystick={openJoystick}
                    openArrangeTools={openArrangeTools}
                    openRotateSlider={openRotateSlider}
                    openTextColorPicker={openTextColorPicker}
                    openFontSelectorTools={openFontSelectorTools}
                    openTextInput={openTextInput}
                />
            )}
            {currentActiveDialog === DialogType.TextInput && (
                <TextSheetContentButtons
                    openAlignmentTool={openAlignmentTool}
                    openJoystick={openJoystick}
                    openArrangeTools={openArrangeTools}
                    openRotateSlider={openRotateSlider}
                    openTextColorPicker={openTextColorPicker}
                    openFontSelectorTools={openFontSelectorTools}
                    openTextInput={openTextInput}
                />
            )}
            {currentActiveDialog === DialogType.TextColorPicker && (
                <ColorPickerSheetContent
                    colorType={ColorType.WordArtAndTextColor}
                    onBackClick={openTextTools}
                    title={t(messages.textColorTitle.id)}
                />
            )}
            {currentActiveDialog === DialogType.FontSelectorTools && (
                <FontSelectorSheetContent onBackClick={openTextTools} />
            )}
        </>
    );
}
TextSheetContentContainer.displayName = "TextSheetContentContainer";
