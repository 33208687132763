import React from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { RotationSlider } from "../../ToolbarComponents/RotateSlider/RotationSlider";

const messages = defineMessages({
    RotateButtonTitleText: {
        id: "easel.ui.rotateButtonSheetContent.RotateButtonTitleText",
        defaultMessage: "Choose rotate angle",
        description: {
            note: "Display at the header of the rotate sheet for mobile"
        }
    }
});

interface RotateButtonSheetContentProps {
    /** Action the back button should take */
    onBackClick: () => void;
}

export function RotateButtonSheetContent({ onBackClick }: RotateButtonSheetContentProps) {
    const { t } = useTranslationSSR();

    return (
        <SheetContent className={classNames("easel-rotate-angle-button-container", "sheet")}>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(messages.RotateButtonTitleText.id)} />
            <div className="sheet-rotation-selector">
                <RotationSlider />
            </div>
        </SheetContent>
    );
}
RotateButtonSheetContent.displayName = "RotateButtonSheetContent";
