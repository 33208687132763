import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Button, AlignIcon } from "@shared/features/StudioChrome";
import { getTrackingDataForSelection } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useSelection, useDesigner } from "@designer-suite";
import { alignmentMessages } from "@shared/features/ContextualToolbar";

interface AlignmentToolButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    /** For opening up the alignment tool panel */
    onClick: () => void;

    /** Whether the button should return the the icon
     * @default false
     */
    showIcon?: boolean;
}

/**
 * This button is responsible for opening the alignment tool
 */
export function AlignmentToolButton({
    className = "tool-icon",
    onClick,
    showIcon,
    ...restOfProps
}: AlignmentToolButtonProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection();

    return (
        <Button
            onClick={() => {
                onClick();
                designer &&
                    designer.eventBus.trigger(
                        STUDIO_TRACKING_EVENTS.CLICK_ALIGN,
                        getTrackingDataForSelection(selection)
                    );
            }}
            className={className}
            {...restOfProps}
        >
            {showIcon && <AlignIcon aria-hidden={true} />}
            <div className="dcl-ctx-button-label">{t(alignmentMessages.align.id)}</div>
        </Button>
    );
}
AlignmentToolButton.displayName = "AlignmentToolButton";
