import React from "react";
import { PremiumFinishButton } from "@presentational";
import { applyTextPremiumFinish, selectedItemsAreOfTypes } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useSelection } from "../designer/useSelection";
import { useDesigner } from "../designer/DesignerProvider";
import { useActiveCanvas } from "../designer/useActiveCanvas";
import { getPremiumFinishes, itemHasPremiumFinishSpotColor } from "../util";
import type { Designer, ItemSelection } from "../@types/designer";

function getIsCompatible(
    designer: Designer | undefined,
    selection: ItemSelection,
    canvas: Canvas | undefined
): designer is Designer {
    return (
        selection.length > 0 &&
        !!designer &&
        !!canvas &&
        getPremiumFinishes(canvas).length !== 0 &&
        selectedItemsAreOfTypes(selection, [ItemTypes.TEXT])
    );
}

function onApply(
    designer: Designer,
    canvas: Canvas | undefined,
    selection: CanvasItem[],
    hasPremiumFinishSpotcolor: boolean
) {
    applyTextPremiumFinish(designer, canvas, selection, hasPremiumFinishSpotcolor);
}

function selectionHasPremiumFinishSpotColor(selection: CanvasItem[]) {
    return !!selection.length && selection.every(itemHasPremiumFinishSpotColor);
}

const getListenEvents = () => "model:change:overprints";

export function PremiumFinishTextButton() {
    const designer = useDesigner();
    const selection = useSelection(getListenEvents());
    const canvas = useActiveCanvas();

    if (!getIsCompatible(designer, selection, canvas)) {
        return null;
    }

    const hasPremiumFinishSpotcolor = selectionHasPremiumFinishSpotColor(selection);

    const onClick = () => {
        onApply(designer, canvas, selection, hasPremiumFinishSpotcolor);
    };

    return <PremiumFinishButton onClick={onClick} isSelected={hasPremiumFinishSpotcolor} />;
}
PremiumFinishTextButton.displayName = "PremiumFinishTextButton";

PremiumFinishTextButton.getIsCompatible = getIsCompatible;
PremiumFinishTextButton.onApply = onApply;
PremiumFinishTextButton.selectionHasPremiumFinishSpotColor = selectionHasPremiumFinishSpotColor;
PremiumFinishTextButton.getListenEvents = getListenEvents;
