import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { selectedItemsAreOfTypes } from "@utilities";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { alignmentMessages } from "@shared/features/ContextualToolbar";
import { TextAlignOptionGroup } from "./TextAlignOptionGroup";
import { updateSelectedItems } from "../../util";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelection } from "../../designer/useSelection";
import type { Designer, ItemSelection } from "../../@types/designer";

function onChange(designer: Designer | undefined, selection: ItemSelection, alignment: Alignment) {
    updateSelectedItems(designer, selection, (item: Item) =>
        item._itemViewModel.model.set("horizontalAlignment", alignment)
    );
}

function getHorizontalAlignment(designer: Designer, selection: ItemSelection) {
    const selectedAlignments = selection.reduce<Alignment[]>((acc, curr: TextItem) => {
        if (acc.includes(curr.horizontalAlignment)) {
            return acc;
        }
        return [...acc, curr.horizontalAlignment];
    }, []);
    return selectedAlignments.length === 1 ? selectedAlignments[0] : "";
}

function getIsCompatible(designer: Designer | undefined, selection: ItemSelection): designer is Designer {
    return designer !== undefined && selectedItemsAreOfTypes(selection, [ItemTypes.TEXT]);
}

const listenEvents = "model:change:horizontalAlignment";
interface AlignmentToolSheetContentProps /* extends Props */ {
    /** Action the back button should take */
    onBackClick: () => void;
}
/**
 * This Sheet is responsible for showing the alignment tools in a sheet
 */
export function AlignmentToolSheetContent({ onBackClick, ...restOfProps }: AlignmentToolSheetContentProps) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection(listenEvents);

    if (!getIsCompatible(designer, selection) || !selection.length) {
        return null;
    }

    const selectedAlignment = getHorizontalAlignment(designer, selection);

    function onChangeAlignment(alignment: Alignment) {
        onChange(designer, selection, alignment);
    }

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(alignmentMessages.align.id)} />
            <SheetContent className="sheet-vertical-selection-set">
                <TextAlignOptionGroup onChange={onChangeAlignment} alignment={selectedAlignment} {...restOfProps} />
            </SheetContent>
        </>
    );
}
AlignmentToolSheetContent.displayName = "AlignmentToolSheetContent";
