import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { LayersControls } from "@presentational";
import { StandardSheetHeader, SheetContent } from "@shared/features/StudioChrome";
import { updateZIndex, disableChangingZIndex, getCurrentCanvas } from "@designer-suite";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelection } from "../../designer/useSelection";

const messages = defineMessages({
    layersToolTitleText: {
        id: "easel.ui.layersToolSheetContent.LayersSheetButtonTitle",
        defaultMessage: "Arrange",
        description: {
            note: "Display at the header of the layersTool sheet for mobile"
        }
    }
});

interface LayersToolSheetContentProps {
    /** Action the back button should take */
    onBackClick: () => void;
}
/**
 * This Sheet is responsible for showing layerstool tool in a sheet
 */
export function LayersToolSheetContent({ onBackClick }: LayersToolSheetContentProps) {
    const { t } = useTranslationSSR();

    const designer = useDesigner();
    const selection = useSelection("model:change:zIndex");
    const selectedItem = selection[0];

    if (!designer || selection.length !== 1) {
        return null;
    }

    const canvas = getCurrentCanvas(designer, selectedItem);

    const { disableBringForward, disableSendBackward } = disableChangingZIndex(selectedItem, canvas);

    return (
        <>
            <StandardSheetHeader onBackClick={onBackClick} sheetTitle={t(messages.layersToolTitleText.id)} />
            <SheetContent className="sheet-vertical-selection-set">
                <LayersControls
                    onClickBringToFront={updateZIndex("bringToFront", selectedItem, designer)}
                    onClickSendToBack={updateZIndex("sendToBack", selectedItem, designer)}
                    onClickBringForward={updateZIndex("bringForward", selectedItem, designer)}
                    onClickSendBackward={updateZIndex("sendBackward", selectedItem, designer)}
                    disableBringForward={disableBringForward}
                    disableSendBackward={disableSendBackward}
                />
            </SheetContent>
        </>
    );
}
LayersToolSheetContent.displayName = "LayersToolSheetContent";
