import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Button, FontFamilyIcon, ExpandingButtonDownArrow } from "@shared/features/StudioChrome";
import classNames from "classnames";
import { useSelection, useDesigner } from "@designer-suite";
import { getTrackingDataForSelection } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useSelectedFonts } from "./useSelectedFonts";
import "./font.scss";

const messages = defineMessages({
    fontFamilyToolTitle: {
        id: "easel.ui.FontButton.Title",
        defaultMessage: "Font Selector",
        description: {
            note: "Opens tool to select a different font"
        }
    },
    fontUndefined: {
        id: "easel.ui.FontButton.fontUndefined",
        defaultMessage: "Choose a style...",
        description: {
            note: "font value for multiple items selected with different fonts"
        }
    }
});

interface Props extends Omit<React.ComponentProps<typeof Button>, "title" | "children" | "onClick"> {
    /** Click handler for when button is clicked */
    onClick: () => void;
}

/**
 * This button is responsible for opening a font selector sheet and displaying current font
 */
export function FontButton(props: Props) {
    const { onClick, className, ...rest } = props;
    const { t } = useTranslationSSR();
    const { selectedFontFamily } = useSelectedFonts();
    const selection = useSelection();
    const designer = useDesigner();

    return (
        <Button
            className={classNames("sheet-fontbutton", className)}
            title={t(messages.fontFamilyToolTitle.id)}
            onClick={() => {
                designer &&
                    designer.eventBus.trigger(
                        STUDIO_TRACKING_EVENTS.CLICK_FONT,
                        getTrackingDataForSelection(selection)
                    );
                onClick();
            }}
            {...rest}
        >
            <FontFamilyIcon aria-hidden={true} className="sheet-fontbutton-icon" />
            <div className="dcl-ctx-button-label font-text-button">
                {selectedFontFamily || t(messages.fontUndefined.id)}
            </div>
            <ExpandingButtonDownArrow aria-hidden={true} className="expandingButton-arrowIconStyle" />
        </Button>
    );
}
FontButton.displayName = "FontButton";
