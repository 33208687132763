import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Button, FontFamilyIcon, ExpandingButtonDownArrow } from "@shared/features/StudioChrome";

import { getTeamsSelectedFont, teamsPlaceholderFilter } from "@utilities";
import { useSelection } from "@designer-suite";

import "../FontSelector/font.scss";

const messages = defineMessages({
    fontFamilyToolTitle: {
        id: "easel.ui.TeamsFontButton.Title",
        defaultMessage: "Font Selector",
        description: {
            note: "Opens tool to select a different font"
        }
    },
    fontUndefined: {
        id: "easel.ui.TeamsFontButton.fontUndefined",
        defaultMessage: "Choose a style...",
        description: {
            note: "font value for multiple items selected with different fonts"
        }
    }
});

interface Props {
    /** Click handler for when button is clicked */
    onClick: () => void;
}

const listenEvents = "model:change:data";

/**
 * This button is responsible for opening a font selector sheet and displaying current font
 */
export function TeamsFontFamilyButton({ onClick }: Props) {
    const { t } = useTranslationSSR();
    const selection = useSelection(listenEvents);
    const { teamsPlaceholderItems } = teamsPlaceholderFilter(selection);

    const selectedFontFamily = getTeamsSelectedFont(teamsPlaceholderItems);

    return (
        <Button className="sheet-fontbutton" title={t(messages.fontFamilyToolTitle.id)} onClick={onClick}>
            <FontFamilyIcon aria-hidden={true} className="sheet-fontbutton-icon" />
            <div className="dcl-ctx-button-label font-text-button">
                {selectedFontFamily || t(messages.fontUndefined.id)}
            </div>
            <ExpandingButtonDownArrow aria-hidden={true} className="expandingButton-arrowIconStyle" />
        </Button>
    );
}
TeamsFontFamilyButton.displayName = "TeamsFontFamilyButton";
