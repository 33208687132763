import React, { useCallback, useEffect } from "react";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { JoystickSheetContent } from "../Joystick";
import { ImagePlaceholderSheetContentButtons } from "./ImagePlaceholderSheetContentButtons";

export function ImagePlaceholderSheetContentContainer() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const openImagePlaceholderTools = useCallback(
        () => setCurrentActiveDialog(DialogType.ImagePlaceholderTools),
        [setCurrentActiveDialog]
    );
    const openJoystick = useCallback(() => setCurrentActiveDialog(DialogType.JoystickTools), [setCurrentActiveDialog]);

    useEffect(() => {
        setCurrentActiveDialog(DialogType.ImagePlaceholderTools);
    }, [setCurrentActiveDialog]);

    // eslint-disable-next-line react/display-name
    function getContent() {
        switch (currentActiveDialog) {
            case DialogType.JoystickTools:
                return <JoystickSheetContent onBackClick={openImagePlaceholderTools} />;
            case DialogType.ImagePlaceholderTools:
                return <ImagePlaceholderSheetContentButtons openJoystick={openJoystick} />;
            default:
                return null;
        }
    }

    return <React.Fragment>{getContent()}</React.Fragment>;
}
ImagePlaceholderSheetContentContainer.displayName = "ImagePlaceholderSheetContentContainer";
