import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { getItemStudioMetadataProperty } from "@utilities";
import { useSelection } from "@designer-suite";
import { EditShapeIcon, Button } from "@shared/features/StudioChrome";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import type { ItemSelection } from "src/easel/designer-suite/@types/designer";

const messages = defineMessages({
    label: {
        id: "easel.ui.buttons.editShape.label",
        defaultMessage: "Edit Shape",
        description: {
            note: "Label for the edit shape button"
        }
    }
});

interface Props {
    /** Click handler
     */
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    /** For overriding styles
     */
    className?: string;
    /** Whether or not the button is disabled */
    isDisabled?: boolean;
    /** Whether the button should return the the icon
     * @default false
     */
    showIcon?: boolean;
}

export function isEditShapeCompatible(selection: ItemSelection) {
    if (selection.length !== 1) {
        return false;
    }
    const shape = selection[0];
    if (shape.itemType !== ItemTypes.SHAPE) {
        return false;
    }
    const metadata = getItemStudioMetadataProperty(shape, "shapeMetadata");
    if (!metadata) {
        return false;
    }
    return true;
}

/**
 * This button is responsible for opening a the edit shapes submenu.
 */
export function EditShapeButton(props: Props) {
    const { showIcon, ...rest } = props;
    const { t } = useTranslationSSR();
    const selection = useSelection();
    if (!isEditShapeCompatible(selection)) {
        return null;
    }
    return (
        <Button className="tool-icon" {...rest}>
            {/* This icon will be updatd when we style the edit shape logic */}
            {showIcon && <EditShapeIcon />}
            <div className="dcl-ctx-button-label">{t(messages.label.id)}</div>
        </Button>
    );
}
EditShapeButton.displayName = "EditShapeButton";
