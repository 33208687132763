import React, { useCallback, useEffect } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { LayersToolSheetContent } from "../LayersTool/LayersToolSheetContent";
import { JoystickSheetContent } from "../Joystick";
import { TablesSheetContentButtons } from "./TablesSheetContentButtons";
import { ColorPickerSheetContent, ColorType } from "../ColorPicker/ColorPickerSheetContent";
import { TableFontSelectorSheetContent } from "../TableFontSelector/TableFontSelectorSheetContent";
import { TableDesignSelectorSheetContent } from "../TableDesignSelector/TableDesignSelectorSheetContent";
import { TableAlignmentToolSheetContent } from "../../ToolbarComponents/Alignment/TableAlignmentToolSheetContent";
import { RotateButtonSheetContent } from "../RotateSheetButton/RotateSheetButtonContent";

const messages = defineMessages({
    tableFontColorTitle: {
        id: "easel.ui.sheets.tablesSheetContentContainer.fontColorTitle",
        defaultMessage: "Table text color"
    },
    tableBackgroundColorTitle: {
        id: "easel.ui.sheets.tablesSheetContentContainer.backgroundColorTitle",
        defaultMessage: "Table background color"
    }
});

export function TablesSheetContentContainer() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();
    const { t } = useTranslationSSR();

    const openTablesTools = useCallback(() => setCurrentActiveDialog(DialogType.TablesTools), [setCurrentActiveDialog]);
    const openJoystick = useCallback(() => setCurrentActiveDialog(DialogType.JoystickTools), [setCurrentActiveDialog]);
    const openArrangeTools = useCallback(
        () => setCurrentActiveDialog(DialogType.ArrangeTools),
        [setCurrentActiveDialog]
    );
    const openFontColorPickerTools = useCallback(
        () => setCurrentActiveDialog(DialogType.TableFontColorPicker),
        [setCurrentActiveDialog]
    );
    const openBackgroundColorPickerTools = useCallback(
        () => setCurrentActiveDialog(DialogType.TableBackgroundColorPicker),
        [setCurrentActiveDialog]
    );
    const openFontSelectorTools = useCallback(
        () => setCurrentActiveDialog(DialogType.FontSelectorTools),
        [setCurrentActiveDialog]
    );
    const openDesignSelectorTools = useCallback(
        () => setCurrentActiveDialog(DialogType.DesignSelectorTools),
        [setCurrentActiveDialog]
    );
    const openAlignmentTool = useCallback(() => setCurrentActiveDialog(DialogType.AlignTool), [setCurrentActiveDialog]);
    const openRotateSlider = useCallback(
        () => setCurrentActiveDialog(DialogType.RotateTools),
        [setCurrentActiveDialog]
    );

    useEffect(() => {
        setCurrentActiveDialog(DialogType.TablesTools);
    }, [setCurrentActiveDialog]);

    return (
        <React.Fragment>
            {currentActiveDialog === DialogType.JoystickTools && <JoystickSheetContent onBackClick={openTablesTools} />}
            {currentActiveDialog === DialogType.ArrangeTools && (
                <LayersToolSheetContent onBackClick={openTablesTools} />
            )}
            {currentActiveDialog === DialogType.RotateTools && (
                <RotateButtonSheetContent onBackClick={openTablesTools} />
            )}
            {currentActiveDialog === DialogType.TablesTools && (
                <TablesSheetContentButtons
                    openJoystick={openJoystick}
                    openArrangeTools={openArrangeTools}
                    openTextColorPicker={openFontColorPickerTools}
                    openBackgroundColorPicker={openBackgroundColorPickerTools}
                    openFontSelectorTools={openFontSelectorTools}
                    openDesignSelectorTools={openDesignSelectorTools}
                    openAlignmentTool={openAlignmentTool}
                    openRotateSlider={openRotateSlider}
                />
            )}
            {currentActiveDialog === DialogType.TableFontColorPicker && (
                <ColorPickerSheetContent
                    colorType={ColorType.TableFontColor}
                    onBackClick={openTablesTools}
                    title={t(messages.tableFontColorTitle.id)}
                />
            )}
            {currentActiveDialog === DialogType.TableBackgroundColorPicker && (
                <ColorPickerSheetContent
                    colorType={ColorType.TableBackgroundColor}
                    onBackClick={openTablesTools}
                    title={t(messages.tableBackgroundColorTitle.id)}
                />
            )}
            {currentActiveDialog === DialogType.FontSelectorTools && (
                <TableFontSelectorSheetContent onBackClick={openTablesTools} />
            )}
            {currentActiveDialog === DialogType.DesignSelectorTools && (
                <TableDesignSelectorSheetContent onBackClick={openTablesTools} />
            )}
            {currentActiveDialog === DialogType.AlignTool && (
                <TableAlignmentToolSheetContent onBackClick={openTablesTools} />
            )}
        </React.Fragment>
    );
}

TablesSheetContentContainer.displayName = "TablesSheetContentContainer";
