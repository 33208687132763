import { defineMessages } from "@vp/i18n-helper";

export const curveMessages = defineMessages({
    curveToolTitleText: {
        id: "easel.ui.curveToolSheetContent.curveToolButtonTitle",
        defaultMessage: "Curve Text",
        description: {
            note: "Display at the header of the word art Curve sheet for mobile"
        }
    },
    curveToolTitle: {
        id: "easel.ui.curveToolbarButton.curveToolTitle",
        defaultMessage: "Curve Tool",
        description: {
            note: "The hover text for the curve button in the toolbar"
        }
    },
    curveToolText: {
        id: "easel.ui.curveToolbarButton.curveToolText",
        defaultMessage: "Curve Text",
        description: {
            note: "Display text for the curve button in the toolbar"
        }
    }
});
