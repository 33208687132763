import type { Designer, ItemSelection } from "../../@types/designer";

/**
 * Check whether the selection is compatible with the Joystick component
 *
 * @param {Designer} designer - reference to the cimpress designer object
 * @param {ItemSelection} selection - selection to filter
 * @returns {boolean} true if selection is compatible, false otherwise
 */
export function isJoystickCompatible(designer: Designer | undefined, selection: ItemSelection) {
    return (
        designer &&
        selection &&
        selection.length >= 1 &&
        !selection.some(selectionItem => selectionItem._itemViewModel.model.get("locked"))
    );
}
