import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { Button, ImageRotateIcon } from "@shared/features/StudioChrome";

const messages = defineMessages({
    RotateToolTitle: {
        id: "easel.ui.RotateButton.Title",
        defaultMessage: "Rotate",
        description: {
            note: "Opens tool to select a different rotate angle"
        }
    }
});

interface Props {
    onClick: () => void;
}

export function RotateSheetButton({ onClick }: Props) {
    const { t } = useTranslationSSR();

    return (
        <Button onClick={onClick} className="tool-icon">
            <ImageRotateIcon aria-hidden={true} />
            <div className="dcl-ctx-button-label">{t(messages.RotateToolTitle.id)}</div>
        </Button>
    );
}
RotateSheetButton.displayName = "RotateSheetButton";
