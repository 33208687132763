import React, { HTMLProps } from "react";
import classNames from "classnames";
import * as styles from "./SheetContent.module.scss";

export function SheetContent({ className, ...restOfProps }: HTMLProps<HTMLDivElement>) {
    return (
        <div
            className={classNames(styles.sheetContent, className)}
            data-dcl-prevent-canvas-items-deselection
            {...restOfProps}
        />
    );
}

SheetContent.displayName = "SheetContent";
