import React, { useCallback, useEffect } from "react";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { LayersToolSheetContent } from "../LayersTool/LayersToolSheetContent";
import { JoystickSheetContent } from "../Joystick";
import { TeamsSheetContentButtons } from "./TeamsSheetContentButtons";
import { ColorPickerSheetContent, ColorType } from "../ColorPicker/ColorPickerSheetContent";
import { RotateButtonSheetContent } from "../RotateSheetButton/RotateSheetButtonContent";
import { TeamsFontSelectorSheetContent } from "../TeamsFontSelector/TeamsFontSelectorSheetContent";

export function TeamsSheetContentContainer() {
    const { currentActiveDialog, setCurrentActiveDialog } = useActiveDialog();

    const openTeamsTools = useCallback(() => setCurrentActiveDialog(DialogType.TeamsTools), [setCurrentActiveDialog]);
    const openJoystick = useCallback(() => setCurrentActiveDialog(DialogType.JoystickTools), [setCurrentActiveDialog]);
    const openArrangeTools = useCallback(
        () => setCurrentActiveDialog(DialogType.ArrangeTools),
        [setCurrentActiveDialog]
    );
    const openFontColorPickerTools = useCallback(
        () => setCurrentActiveDialog(DialogType.TeamsFontColorPicker),
        [setCurrentActiveDialog]
    );
    const openFontSelectorTools = useCallback(
        () => setCurrentActiveDialog(DialogType.FontSelectorTools),
        [setCurrentActiveDialog]
    );
    const openRotateSlider = useCallback(
        () => setCurrentActiveDialog(DialogType.RotateTools),
        [setCurrentActiveDialog]
    );

    useEffect(() => {
        setCurrentActiveDialog(DialogType.TeamsTools);
    }, [setCurrentActiveDialog]);

    return (
        <React.Fragment>
            {currentActiveDialog === DialogType.JoystickTools && <JoystickSheetContent onBackClick={openTeamsTools} />}
            {currentActiveDialog === DialogType.ArrangeTools && <LayersToolSheetContent onBackClick={openTeamsTools} />}
            {currentActiveDialog === DialogType.RotateTools && (
                <RotateButtonSheetContent onBackClick={openTeamsTools} />
            )}
            {currentActiveDialog === DialogType.TeamsTools && (
                <TeamsSheetContentButtons
                    openJoystick={openJoystick}
                    openArrangeTools={openArrangeTools}
                    openTextColorPicker={openFontColorPickerTools}
                    openFontSelectorTools={openFontSelectorTools}
                    openRotateSlider={openRotateSlider}
                />
            )}
            {currentActiveDialog === DialogType.TeamsFontColorPicker && (
                <ColorPickerSheetContent colorType={ColorType.TeamsFontColor} onBackClick={openTeamsTools} />
            )}
            {currentActiveDialog === DialogType.FontSelectorTools && (
                <TeamsFontSelectorSheetContent onBackClick={openTeamsTools} />
            )}
        </React.Fragment>
    );
}

TeamsSheetContentContainer.displayName = "TeamsSheetContentContainer";
