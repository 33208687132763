import React from "react";
import { setShowTableEditorModal, useAppDispatch } from "@shared/redux";
import { EditTableButton } from "@shared/features/ContextualToolbar";
import { useSelection } from "../../designer/useSelection";
import { useTableSelectionFilter } from "../../hooks/useTableSelectionFilter";

interface Props {
    showIcon?: boolean;
}

export function EditTableTool({ showIcon }: Props) {
    const dispatch = useAppDispatch();
    const selection = useSelection();
    const { tableItems } = useTableSelectionFilter(selection);

    if (tableItems.length !== 1) {
        return null;
    }

    const onClick = () => {
        dispatch(setShowTableEditorModal(true));
    };

    return <EditTableButton onClick={onClick} showIcon={showIcon} />;
}

EditTableTool.displayName = "EditTableTool";
