import React from "react";
import { Divider } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import {
    CropTool,
    DuplicateTool,
    LockTool,
    SharpenTool,
    useSelection,
    useDesigner,
    useActiveCanvas,
    DeleteTool,
    KnockoutButton,
    AllowStretchingButton,
    RestoreProportionsButton,
    isLayersToolCompatible
} from "@designer-suite";
import { LayersSheetButton, SheetContent, SheetHorizontalScroller } from "@shared/features/StudioChrome";
import { ColorPickerButton, ColorIconType } from "@presentational";
import { getTrackingDataForSelection, isPremiumFinishImageButtonCompatible, isSingleColorCanvas } from "@utilities";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { ReplacePlaceholderQRCodeTool } from "@five/components/QRCodes/ReplacePlaceholderQRCodeTool";
import { useDecorationTechnology } from "../../../utilities/hooks/useDecorationTechnology";
import { ReplaceImageTool } from "../../ToolbarComponents/ReplaceImages/ReplaceImageTool";
import { ImageChangeColorButton, isCompatible } from "../../ToolbarComponents/Buttons/ImageChangeColorButton";
import { getIsRecolorizationCompatible } from "../../ToolbarComponents/Buttons/ImageRecolorizationTool";
import { PremiumFinishSheetButton } from "../PremiumFinish/PremiumFinishSheetButton";
import { recolorizeMessages } from "../../color/messages";
import "./imageSheet.scss";
import { RotateSheetButton } from "../RotateSheetButton/RotateSheetButton";

interface ImageSheetContentProps {
    /** Action to open the joystick */
    openJoystick: () => void;
    /** Action to open the layersTool */
    openArrangeTools: () => void;
    /** Action to open the recolorization tool */
    openColorTools: () => void;
    /** Action to open the single color image color picker */
    openSingleColorTools: () => void;
    /** Action to open the rotate slider */
    openRotateSlider: () => void;
}

export function ImageSheetContentButtons({
    openJoystick,
    openArrangeTools,
    openColorTools,
    openSingleColorTools,
    openRotateSlider
}: ImageSheetContentProps) {
    const { t } = useTranslationSSR();
    const selection = useSelection();
    const designer = useDesigner();
    const isSingleColor = isSingleColorCanvas(designer);
    const canvas = useActiveCanvas();
    const showPremiumFinishTogglePremiumFinish = isPremiumFinishImageButtonCompatible(
        designer,
        selection,
        canvas,
        true
    );
    const decoTech = useDecorationTechnology();

    if (!designer || selection.length !== 1) {
        return null;
    }

    const isSingleColorImage = selection[0]._itemViewModel.get("isOneColor");
    const showEmbroideryTools =
        isCompatible(selection, decoTech) && decoTech === DecorationTechnologiesSimple.EMBROIDERY;

    return (
        <SheetContent className="image-sheet-content">
            <SheetHorizontalScroller>
                {showPremiumFinishTogglePremiumFinish && (
                    <>
                        <PremiumFinishSheetButton showIcon />
                        <Divider marginY={4} />
                    </>
                )}
                {showEmbroideryTools && (
                    <>
                        <DeleteTool showIcon />
                        <ImageChangeColorButton showIcon />
                        <CropTool showIcon alwaysShow />
                        <ReplaceImageTool showIcon isMobile />
                        <RotateSheetButton onClick={openRotateSlider} />
                        <LayersSheetButton
                            showIcon
                            onClick={() => {
                                designer &&
                                    designer.eventBus.trigger(
                                        STUDIO_TRACKING_EVENTS.CLICK_ARRANGE,
                                        getTrackingDataForSelection(selection)
                                    );
                                openArrangeTools();
                            }}
                            isDisabled={!isLayersToolCompatible(designer, selection, canvas)}
                        />
                        <LockTool showIcon />
                    </>
                )}
                {!showEmbroideryTools && (
                    <>
                        <DeleteTool showIcon />
                        <ImageChangeColorButton showIcon />
                        <CropTool showIcon alwaysShow />
                        <RotateSheetButton onClick={openRotateSlider} />
                        {!isSingleColor && (
                            <ColorPickerButton
                                title={t(recolorizeMessages.recolorizeButtonLabel.id)}
                                onClick={isSingleColorImage ? openSingleColorTools : openColorTools}
                                isDisabled={!getIsRecolorizationCompatible(designer, selection, canvas)}
                                displayType={"both"}
                                icon={ColorIconType.BucketColorIcon}
                            />
                        )}
                        <ReplaceImageTool showIcon isMobile />
                        <ReplacePlaceholderQRCodeTool showIcon />
                        <KnockoutButton showIcon />
                        <LayersSheetButton
                            showIcon
                            onClick={() => {
                                designer &&
                                    designer.eventBus.trigger(
                                        STUDIO_TRACKING_EVENTS.CLICK_ARRANGE,
                                        getTrackingDataForSelection(selection)
                                    );
                                openArrangeTools();
                            }}
                            isDisabled={!isLayersToolCompatible(designer, selection, canvas)}
                        />
                        <SharpenTool showIcon alwaysShow />
                        <DuplicateTool showIcon alwaysShow />
                        <LockTool showIcon />
                        <AllowStretchingButton showIcon />
                        <RestoreProportionsButton showIcon />
                    </>
                )}
            </SheetHorizontalScroller>
        </SheetContent>
    );
}

ImageSheetContentButtons.displayName = "ImageSheetContentButtons";
