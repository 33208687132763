import React from "react";
import { SheetContent, SheetHorizontalScroller } from "@shared/features/StudioChrome";
import { RemoveTableColumnTool } from "../../ToolbarComponents/Buttons/RemoveTableColumnTool";
import { AddTableColumnTool } from "../../ToolbarComponents/Buttons/AddTableColumnTool";
import { TableColumnAlignTool } from "../../ToolbarComponents/Alignment/TableColumnAlignTool";

// Not using a container, as currently there are no sub sheets

export function TableColumnContentButtons() {
    return (
        <SheetContent>
            <SheetHorizontalScroller>
                <AddTableColumnTool direction="left" />
                <AddTableColumnTool direction="right" />
                <TableColumnAlignTool />
                <RemoveTableColumnTool />
            </SheetHorizontalScroller>
        </SheetContent>
    );
}

TableColumnContentButtons.displayName = "TableColumnContentButtons";
